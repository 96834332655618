import { H1, Icon, Tabs } from '@dnb/eufemia';
import { people_1 as PeopleIcon } from '@dnb/eufemia/icons';
import type {
  ApiGrantStatus,
  ProductionGrantDto,
} from '@portals/shared/admin/ApiGrantDto';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

interface GrantRow {
  id: string;
  app: string;
  api: string;
  owner: string;
  ownership: string;
  organization: string;
  createdAt: string;
  updatedAt: string;
}

const columns: Column<GrantRow>[] = [
  { header: 'App', attribute: 'app' },
  {
    header: 'Owner',
    attribute: 'owner',
    render: (row) => (
      <>
        {row.owner}
        {row.ownership === 'team' && <Icon icon={PeopleIcon} left="x-small" />}
      </>
    ),
  },
  { header: 'Organization', attribute: 'organization' },
  { header: 'API', attribute: 'api' },
  {
    header: 'Requested',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDate(createdAt),
  },
  {
    header: 'Updated',
    attribute: 'updatedAt',
    render: ({ updatedAt }) => humanDate(updatedAt),
  },
];

const TABS = [
  { title: 'Pending', key: 'pending' },
  { title: 'Approved', key: 'approved' },
  { title: 'Rejected', key: 'rejected' },
  { title: 'Contacted', key: 'contacted' },
];

export default function GrantList(): JSX.Element {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>('pending');

  const { data: grants } = useSWR<ProductionGrantDto[]>('/api-grants');

  const rows = useMemo(() => {
    if (!grants) {
      return [];
    }

    const rowsByStatus = (status: ApiGrantStatus) =>
      grants.reduce<GrantRow[]>(
        (rows, app) =>
          rows.concat(
            app.grants
              .filter((grant) => status === grant.status)
              .map((grant) => ({
                id: app.id,
                owner: app.appOwnerName || 'N/A',
                ownership: app.owner.type,
                organization: app.organizationName || 'N/A',
                api: grant.api.name,
                app: app.name,
                createdAt: grant.createdAt,
                updatedAt: grant.updatedAt,
              })),
          ),
        [],
      );

    switch (activeTab) {
      case 'pending': {
        return rowsByStatus('pending');
      }
      case 'approved': {
        return rowsByStatus('approved');
      }
      case 'contacted': {
        return rowsByStatus('contacted');
      }
      default: {
        return rowsByStatus('rejected');
      }
    }
  }, [activeTab, grants]);

  if (!grants) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>

      <H1 top="large">API Grants</H1>
      <Tabs
        data={TABS}
        on_change={({ key }) => setActiveTab(key)}
        selected_key={activeTab}
      >
        <DataTable
          columns={columns}
          data={rows}
          defaultSortKey="app"
          filterBy={[
            'app',
            'api',
            'owner',
            'organization',
            'updatedAt',
            'createdAt',
          ]}
          onShow={({ id }) => navigate(id)}
          reverseSort
          stateKey={activeTab}
        />
      </Tabs>
    </>
  );
}

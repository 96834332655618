import { request } from '@/request';

export function deleteUser(id: string) {
  return request(`/users/${id}`, 'delete');
}

export function deleteUsers(ids: string[]) {
  return request(`/users-expired`, 'delete', ids);
}

export function notifyUsersAboutExpiration(ids: string[]) {
  return request(`/users/notify`, 'post', ids);
}

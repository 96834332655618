import type {
  AddTeamMemberResponse,
  TeamMemberDto,
} from '@portals/shared/portal/TeamDto';

import { request } from '@/request';

export function setTeamAdmin(
  id: string,
  userId: string,
  isAdmin: boolean,
): Promise<void> {
  return request(`/teams/${id}/users/${userId}`, 'patch', {
    isAdmin,
  });
}

export function saveTeamScopePrefix(
  teamId: string,
  scopePrefix: string,
): Promise<void> {
  return request(`/teams/${teamId}/scopePrefixes/${scopePrefix}`, 'patch');
}

export function deleteTeamScopePrefix(
  teamId: string,
  scopePrefix: string,
): Promise<void> {
  return request(`/teams/${teamId}/scopePrefixes/${scopePrefix}`, 'delete');
}

export async function addMembersToTeam(
  teamId: string,
  emails: string[],
): Promise<AddTeamMemberResponse> {
  return request<AddTeamMemberResponse>(`/teams/${teamId}/members`, 'post', {
    memberEmails: emails,
  });
}

export async function removeMember(
  teamId: string,
  memberId: string,
): Promise<TeamMemberDto[]> {
  return request<TeamMemberDto[]>(
    `/teams/${teamId}/members/${memberId}`,
    'delete',
  );
}

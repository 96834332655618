import type { SpacingElementProps } from '@dnb/eufemia/shared/types';
import classnames from 'classnames';
import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import style from './index.module.css';

type CardProps = ComponentPropsWithoutRef<'div'> &
  SpacingElementProps &
  PropsWithChildren<{
    to?: string;
    direction?: 'horizontal' | 'vertical';
    onClick?: () => void;
    selected?: boolean;
  }>;

export default function Card({
  to,
  direction = 'vertical',
  className,
  children,
  onClick,
  selected,
}: CardProps): JSX.Element {
  const navigate = useNavigate();

  let clickable = null;
  if (to) {
    clickable = {
      onClick: () => navigate(to),
      tabIndex: 0,
    };
  } else if (onClick) {
    clickable = { onClick, tabIndex: 0 };
  }

  return (
    <div
      {...clickable}
      className={classnames(
        {
          [style['Card']]: true,
          [style['Clickable']]: !!clickable,
          [style['Row']]: direction === 'horizontal',
          [style['Card--selected']]: selected,
        },
        className,
      )}
    >
      {children}
    </div>
  );
}

import {
  Autocomplete,
  Button,
  Dl,
  Dt,
  FormRow,
  FormSet,
  H1,
  HelpButton,
  Input,
  P,
  Section,
} from '@dnb/eufemia';
import {
  projectForm,
  type ProjectWithTeamsDto,
} from '@portals/shared/admin/ProjectDto';
import type { TeamDto } from '@portals/shared/admin/TeamDto';
import { useAsync, useEufemiaForm } from '@portals/shared-frontend/hooks';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { object, string } from 'zod';

import { deleteProject } from '@/api/project';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import DataTable, { Column } from '@/components/DataTable';
import DeleteDialog from '@/components/DeleteDialog';
import LoadingModal from '@/components/LoadingModal';
import { request } from '@/request';

const COLUMNS: Column<TeamDto>[] = [
  { header: 'Id', attribute: 'id' },
  { header: 'Name', attribute: 'name' },
  { header: 'Description', attribute: 'description' },
];

const projectFormWithId = projectForm.extend({
  id: string().uuid().optional(),
});

export const addTeamForm = object({
  teamId: string().min(1).uuid(),
});

export default function ProjectForm(): JSX.Element {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const {
    data: project,
    isValidating: projectLoading,
    mutate,
  } = useSWR<ProjectWithTeamsDto>(projectId ? `/projects/${projectId}` : null);
  const { data: teams, isValidating: teamsLoading } = useSWR<TeamDto[]>(
    projectId ? `/teams` : null,
  );

  const {
    controller,
    controller: { setValues },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(projectFormWithId, {});

  const {
    controller: teamController,
    handleSubmit: teamSubmit,
    submitting: teamSubmiting,
  } = useEufemiaForm(addTeamForm, {});

  useEffect(() => {
    if (project) {
      setValues(project);
    }
  }, [project, setValues]);

  useEffect(() => {
    if (controller.values.ciamUatAppId === '') {
      controller.setValue('ciamUatAppId', null);
    }
    if (controller.values.ciamProdAppId === '') {
      controller.setValue('ciamProdAppId', null);
    }
  }, [
    controller,
    controller.values.ciamProdAppId,
    controller.values.ciamUatAppId,
  ]);

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        //Remove ciamUatAppId and ciamProdAppId if they are empty
        if (!data.ciamUatAppId) {
          delete data.ciamUatAppId;
        }
        if (!data.ciamProdAppId) {
          delete data.ciamProdAppId;
        }
        await request(
          data.id ? `/projects/${data.id}` : '/projects',
          data.id ? 'patch' : 'post',
          data,
        );
        if (!data.id) {
          navigate('..');
        }
      },
      [navigate],
    ),
  );

  const onDelete = useAsync(async () => {
    if (projectId) {
      await deleteProject(projectId);
      navigate('/projects');
    }
  }, [navigate, projectId]);

  const onAddTeam = teamSubmit(
    useCallback(
      async (data) => {
        await request('/project-team-relation', 'post', {
          teamId: data.teamId,
          projectId: projectId,
        });
        mutate();
        teamController.setValue('teamId', null);
      },
      [mutate, projectId, teamController],
    ),
  );

  const addTeam = (teamId: string) => {
    teamController.setValue('teamId', teamId);
  };

  const detachTeam = useAsync(
    async ({ id }: TeamDto) => {
      await request(`/project-team-relation`, 'delete', {
        projectId,
        teamId: id,
      });
      mutate();
    },
    [mutate, projectId],
  );

  return (
    <>
      {(projectLoading || teamsLoading || teamSubmiting) && <LoadingModal />}
      <BackButton to="/projects">Projects</BackButton>
      <Container centered>
        {submitting && <LoadingModal />}

        <H1 top="large">{projectId ? 'Update project' : 'Create project'}</H1>
        <P>
          An project is an entity that maps to ciam applications. Its primary
          purpose is to group clients for the purpose of effective tracking.
        </P>
        <FormSet label_direction="vertical" on_submit={onSubmit}>
          <Section spacing="x-large" style_type="white">
            <FormRow top="medium">
              <Input
                label="Project name"
                size={40}
                suffix={
                  <HelpButton size="small">
                    This is the name of the Project.
                  </HelpButton>
                }
                {...register.input('name')}
              />
            </FormRow>
            <FormRow top="medium">
              <Input
                label="Project description"
                size={40}
                suffix={
                  <HelpButton size="small">
                    This is the description of the Project.
                  </HelpButton>
                }
                {...register.input('description')}
              />
            </FormRow>
            <FormRow top="medium">
              <Input
                label="Ciam Uat App Id"
                size={40}
                suffix={
                  <HelpButton size="small">
                    This is the ciam application id in UAT env.
                  </HelpButton>
                }
                {...register.input('ciamUatAppId')}
              />
            </FormRow>
            <FormRow top="medium">
              <Input
                label="Ciam Prod App Id"
                size={40}
                suffix={
                  <HelpButton size="small">
                    This is the ciam application id in PROD env.
                  </HelpButton>
                }
                {...register.input('ciamProdAppId')}
              />
            </FormRow>
            <FormRow top="medium">
              <Button
                disabled={submitting}
                icon="save"
                icon_position="left"
                type="submit"
              >
                Save
              </Button>
            </FormRow>
          </Section>
        </FormSet>
        <Section spacing="x-large" style_type="divider">
          {project?.teams && (
            <DataTable
              barContent={
                <FormSet on_submit={onAddTeam}>
                  <Section spacing="x-large" style_type="white">
                    <FormRow>
                      <Autocomplete
                        data={teams?.map((team) => ({
                          selected_key: team.id,
                          content: (
                            <Dl>
                              <Dt>{team.name}</Dt>
                              <P size="small">{team.id}</P>
                            </Dl>
                          ),
                        }))}
                        // label="Add team"
                        on_change={({ data }) => addTeam(data.selected_key)}
                      />
                      <Button
                        disabled={submitting}
                        icon="save"
                        icon_position="left"
                        left="medium"
                        type="submit"
                      >
                        Add
                      </Button>
                    </FormRow>
                  </Section>
                </FormSet>
              }
              columns={COLUMNS}
              data={project?.teams}
              defaultSortKey="name"
              filterBy={['name', 'description']}
              onDelete={detachTeam.execute}
            />
          )}
        </Section>

        <Section innerSpace={{ bottom: 'large', top: 'large' }}>
          <DeleteDialog
            loading={onDelete.waiting}
            onDelete={onDelete.execute}
            text="This will permanently delete the project. This action cannot be undone."
            title="Are you sure you want to delete this project?"
          />
        </Section>
      </Container>
    </>
  );
}

import { boolean, object, string, z } from 'zod';

import { UUID_MATCHER } from '../common/matchers';
import { INVALID_UUID } from '../common/messages';
import type { ApiDto } from './ApiDto';
import type { AppDto } from './AppDto';
import type { ScopeDto } from './ScopeDto';
import type { TeamDto } from './TeamDto';
import type { UserDto } from './UserDto';

export enum ReviewStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  LEVEL1_APPROVED = 'LEVEL1_APPROVED',
  PRESUBMIT = 'PRESUBMIT',
}
export type AppApiEntitlementRequestResponseDto = {
  id: string;
  appName: string;
  owner: string;
  organization: string;
  apiName: string;
  reviewStatus: ReviewStatus;
  requested: string;
  updated: string;
};

export type AppApiEntitlementRequestDetailsResponseDto = {
  createdAt: string;
  reviewChecklist: unknown | null;
  reviewComment: string | null;
  reviewedAt: string | null;
  reviewedBy: string | null;
  reviewStatus: ReviewStatus;
  termsOfUseAcceptedAt: string;
  updatedAt: string;
  useCase: string;
  app: AppDto;
  api: ApiDto;
  user: UserDto | null;
  team: TeamDto | null;
  scopes: ScopeDto[];
  externalTicketComment: string | null;
  externalTicketId: string | null;
};

export const reviewChecklistSchema = object({
  receivedFirstLevelApprovalFromApiProvider: boolean(),
});

export const handleAppApiEntitlementRequestSchema = object({
  appId: string().regex(UUID_MATCHER, INVALID_UUID),
  apiId: string().regex(UUID_MATCHER, INVALID_UUID),
  liveMode: boolean(),
  scopeIds: string().regex(UUID_MATCHER, INVALID_UUID).array(),
  reviewComment: string().nullable(),
  decision: string(),
  checklist: string(),
});

export type EntitlementRequestInputDto = z.input<
  typeof handleAppApiEntitlementRequestSchema
>;

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import type { ErrorDto } from '@portals/shared/common/ErrorDto';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';

import { loginRequest, msalInstance } from './authConfig';

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete';

export async function request<T>(
  url: string,
  method: Method,
  body?: object,
): Promise<T> {
  const token = await getAzureToken();
  if (!token) {
    throw new Error('Failed to fetch token');
  }

  const headers = new Headers();
  // TODO: We should be using the Authorization header
  headers.append('aad-token', token);

  const result = await fetch(`/service${url}`, {
    method: method.toUpperCase(),
    headers,
    body: JSON.stringify(body),
  });

  if (result.status > 299) {
    let body: ErrorDto;

    try {
      body = await result.json();
    } catch {
      throw new Error('Unknown error');
    }

    throw new ApiError(result.status, body);
  }

  return result.status !== 204 &&
    result.headers.get('Content-Type') === 'application/json'
    ? result.json()
    : result.text();
}

export async function getAzureToken(): Promise<string | null> {
  const [account] = msalInstance.getAllAccounts();

  if (!account) return null;

  const claims = account.idTokenClaims;
  let forceRefresh = false;

  if (
    hasOwnProperty(claims, 'exp') &&
    typeof claims.exp === 'number' &&
    claims.exp * 1000 - 60_000 < Date.now()
  ) {
    // Force refresh if token expires in less than 60 seconds
    // TODO: Remove this hack by using an access token with a custom scope
    // The ID token is not renewed because of a bug in MSAL, but an access token is preferred anyways.
    // Bug: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
    forceRefresh = true;
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
      forceRefresh,
    });
    return response.idToken;
  } catch (error) {
    // Catch interaction_required errors and call interactive method to resolve
    if (error instanceof InteractionRequiredAuthError) {
      try {
        await msalInstance.acquireTokenRedirect({
          ...loginRequest,
          account,
        });
      } catch (error_) {
        // eslint-disable-next-line no-console
        console.log(error_);
      }
    } else {
      //TODO check for better ways
      localStorage.clear();
      location.reload();
    }

    return null;
  }
}

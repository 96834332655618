export enum EventType {
  CREATION_REQUESTED = 'CREATION_REQUESTED',
  DELETION_REQUESTED = 'DELETION_REQUESTED',
  CREATION_DENIED = 'CREATION_DENIED',
  DELETION_DENIED = 'DELETION_DENIED',
  CREATION_APPROVED = 'CREATION_APPROVED',
  CREATION_AUTO_APPROVED = 'CREATION_AUTO_APPROVED',
  UPDATE_AUTO_APPROVED = 'UPDATE_AUTO_APPROVED',
  DELETION_APPROVED = 'DELETION_APPROVED',
  DELETION_AUTO_APPROVED = 'DELETION_AUTO_APPROVED',
  DELETION_BY_ADMIN = 'DELETION_BY_ADMIN',
}

export type EventsMapType = {
  event: EventType;
  desciption: string;
  statusClass: 'Status--pending' | 'Status--denied' | 'Status--approved';
};

export const EventTypeDetails: EventsMapType[] = [
  {
    event: EventType.CREATION_REQUESTED,
    desciption: 'The api owner has submitted a scope creation request.',
    statusClass: 'Status--pending',
  },
  {
    event: EventType.DELETION_REQUESTED,
    desciption: 'The api owner has submitted a scope deletion request.',
    statusClass: 'Status--pending',
  },
  {
    event: EventType.CREATION_DENIED,
    desciption: 'The admin has denied the scope creation request.',
    statusClass: 'Status--denied',
  },
  {
    event: EventType.DELETION_DENIED,
    desciption: 'The admin has denied the scope deletion request.',
    statusClass: 'Status--denied',
  },
  {
    event: EventType.CREATION_APPROVED,
    desciption: 'The admin has approved the scope creation request.',
    statusClass: 'Status--approved',
  },
  {
    event: EventType.CREATION_AUTO_APPROVED,
    desciption:
      'The scope creation request has been approved automatically. This happens for verified team with a given scope prefix.',
    statusClass: 'Status--approved',
  },
  {
    event: EventType.UPDATE_AUTO_APPROVED,
    desciption:
      'The scope description update request has been approved automatically.',
    statusClass: 'Status--approved',
  },

  {
    event: EventType.DELETION_APPROVED,
    desciption: 'The admin has approved the scope deletion request.',
    statusClass: 'Status--approved',
  },
  {
    event: EventType.DELETION_AUTO_APPROVED,
    desciption:
      'The scope deletion request has been approved automatically. This happens when the scope is not present in CIAM and therefore it is only deleted from portals.',
    statusClass: 'Status--approved',
  },
  {
    event: EventType.DELETION_BY_ADMIN,
    desciption: `The scope has been deleted directly by developer portal's admin.`,
    statusClass: 'Status--approved',
  },
];

export const reviewChecklistTitleMap: Record<string, string> = {
  prefixIsMatching: 'Prefix matches other scopes associated with this API',
  notAssociatedWithOtherApis:
    'Scope is not associated with another API in CIAM',
  apiVersionDeprecated: 'The API version has been properly deprecated',
  ciamApproval: 'Approval from CIAM/Identity',
};

import { object, string } from 'zod';
export const SCOPE_PREFIX_MATCHER = /^(?=[\dA-Za-z])[\w.-]+\.$/;
export const INVALID_SCOPE_PREFIX =
  'Must be a series of lowercased words (a-z) or numbers concatenated with hyphens, underscores or dots (-._). It should ends with a dot (.)';

export interface TeamScopePrefixDto {
  id: string;
  createdAt: string;
  createdBy: string | null;
  updatedAt: string;
  scopePrefix: string;
  teamId: string;
}
export const createTeamScopePrefix = object({
  scopePrefix: string()
    .min(1)
    .regex(SCOPE_PREFIX_MATCHER, INVALID_SCOPE_PREFIX),
});

import {
  Avatar,
  Button,
  Dialog,
  Div,
  Dropdown,
  FormStatus,
  H2,
  Hr,
  InfoCard,
  Input,
  P,
  Space,
  Span,
} from '@dnb/eufemia';
import {
  add_medium as AddMediumIcon,
  loupe as LoupeIcon,
  subtract_circled_medium as SubtractCircledIcon,
} from '@dnb/eufemia/icons';
import type { TeamDetailsDto } from '@portals/shared/admin/TeamDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { keyBy } from '@portals/shared-frontend/utils';
import { format } from 'date-fns';
import { type ReactFragment, useEffect, useMemo, useState } from 'react';
import { useSWRConfig } from 'swr';

import { removeMember, setTeamAdmin } from '@/api/teams';
import LoadingModal from '@/components/LoadingModal';

import Card from '../../../components/Card';
import AddTeamMember from '../AddTeamMember';

import style from './index.module.css';

type MemberSectionProps = {
  team: TeamDetailsDto;
};

const dropdownData = [
  {
    key: 'member',
    selected_key: 'member',
    content: 'Member',
    selected_value: 'Member',
  },
  {
    key: 'remove',
    selected_key: 'remove',
    content: (
      <Div className={style['LeaveTeamButton']}>
        Remove
        <SubtractCircledIcon />
      </Div>
    ),
    selected_value: 'Remove',
  },
  {
    key: 'admin',
    selected_key: 'admin',
    content: 'Admin',
  },
];
export default function MembersSection({
  team,
}: MemberSectionProps): JSX.Element {
  const { mutate } = useSWRConfig();

  const [search, setSearch] = useState('');
  const [errorMessage, setErrorMessage] = useState<ReactFragment>('');
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [memberIdToRemove, setMemberIdToRemove] = useState<string>();

  const filteredMembers = useMemo(
    () =>
      team.members.filter(({ firstName, lastName, email }) =>
        [firstName, lastName, email].some((property) =>
          property.toLowerCase().includes(search.toLowerCase()),
        ),
      ),
    [team, search],
  );

  const membersById = useMemo(
    () => (team?.members ? keyBy(team?.members, 'id') : {}),
    [team],
  );

  const onSetAdmin = useAsync(
    async (memberId: string) => {
      setErrorMessage('');
      if (team) {
        await setTeamAdmin(team.id, memberId, true);
        await mutate(`/teams/${team.id}`);
      }
    },
    [mutate, team],
  );

  const onRemoveAdmin = useAsync(
    async (memberId: string) => {
      setErrorMessage('');
      const otherAdmins = team.members.filter(
        (member) => member.isAdmin && member.id != memberId,
      );
      if (otherAdmins.length === 0) {
        setErrorMessage(
          'The team must have at least one admin. Please make someone else admin first',
        );
        return;
      }

      await setTeamAdmin(team.id, memberId, false);
      await mutate(`/teams/${team.id}`);
    },
    [mutate, setErrorMessage, team],
  );

  const onRemoveMemberFromTeam = useAsync(
    async (memberId: string) => {
      setErrorMessage('');
      await removeMember(team.id, memberId);
      await mutate(`/teams/${team.id}`);
    },
    [mutate, team],
  );

  const onChange = useAsync(
    async (key: string, memberId: string) => {
      switch (key) {
        case 'admin': {
          return await onSetAdmin.execute(memberId);
        }
        case 'remove': {
          return setMemberIdToRemove(memberId);
        }
        case 'member': {
          return await onRemoveAdmin.execute(memberId);
        }
        // No default
      }
    },
    [onRemoveAdmin, onSetAdmin],
  );

  useEffect(() => {
    setErrorMessage('');
  }, [team]);

  const waiting = onRemoveAdmin.waiting || onSetAdmin.waiting;
  if (showAddMembersModal) {
    return <AddTeamMember handleClose={setShowAddMembersModal} />;
  }

  return (
    <>
      {waiting && <LoadingModal />}
      <H2>Members</H2>

      <Space className={style['Actions']}>
        <P top="small">
          {team.members.length} {team.members.length > 1 ? 'members' : 'member'}
        </P>
        <div>
          <Button
            icon={AddMediumIcon}
            icon_position="left"
            onClick={() => setShowAddMembersModal(true)}
            right="small"
            variant="secondary"
          >
            Add new members
          </Button>
        </div>
        <Input
          icon={LoupeIcon}
          on_change={({ value }) => setSearch(value)}
          placeholder="Search for members"
        />
      </Space>

      {filteredMembers.length === 0 && (
        <InfoCard
          centered
          text="We could not find the user you are looking for."
          top="small"
        />
      )}

      <Space className={style['Members']} top="small">
        {filteredMembers.map((member) => (
          <Card className={style['MemberTile']} key={member.id}>
            <div className={style['MemberBoxDetails']}>
              <div className={style['MemberDetails']}>
                <Avatar
                  className={style['Avatar']}
                  right="small"
                  variant="secondary"
                >
                  {member.firstName[0]}
                </Avatar>

                <Space className={style['Name']}>
                  <P>
                    {member.firstName} {member.lastName}
                  </P>
                  <Span className={style['EmailAndDate']}>{member.email}</Span>
                </Space>
              </div>

              <Space className={style['DateDetails']}>
                <P>Joined</P>
                <Span className={style['EmailAndDate']}>
                  {format(new Date(member.joinedAt), 'dd.MM.yyy')}
                </Span>
              </Space>
            </div>

            <Hr className={style['Divider']} />

            <div className={style['MemberBoxActions']}>
              <Dropdown
                action_menu
                align_dropdown="left"
                data={dropdownData}
                on_change={(event) =>
                  onChange.execute(event.data.key, member.id)
                }
                value={member.isAdmin ? 'admin' : 'member'}
                variant="tertiary"
              />
            </div>
          </Card>
        ))}
      </Space>
      <FormStatus text={errorMessage} top="medium" />
      {!!memberIdToRemove && (
        <Dialog
          className={style['SVG']}
          confirmText="Remove"
          description={
            <>
              This will permanently remove{' '}
              {membersById[memberIdToRemove].firstName} from the team.
            </>
          }
          icon={<SubtractCircledIcon color="red" />}
          omitTriggerButton
          onClose={() => {
            setMemberIdToRemove(undefined);
          }}
          onConfirm={() => {
            onRemoveMemberFromTeam.execute(memberIdToRemove);
            setMemberIdToRemove(undefined);
          }}
          openState={!!memberIdToRemove}
          title={`Are you sure you want to remove ${membersById[memberIdToRemove].firstName}?`}
          top="medium"
          variant="confirmation"
        />
      )}
    </>
  );
}

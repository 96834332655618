import type { ScopeAuditLogDto } from '@portals/shared/admin/ScopeAuditLogDto';
import type {
  ScopeRequestCreationAndApprovalInputDto,
  ScopeRequestInputDto,
} from '@portals/shared/admin/ScopeRequestDto';

import { request } from '@/request';

export function approveScope(
  id: string,
  data: ScopeRequestInputDto,
): Promise<ScopeAuditLogDto> {
  return request(`/scope-requests/${id}/approve`, 'post', data);
}

export function denyScope(
  id: string,
  data: ScopeRequestInputDto,
): Promise<ScopeAuditLogDto> {
  return request(`/scope-requests/${id}/deny`, 'post', data);
}

export function createScope(
  data: ScopeRequestCreationAndApprovalInputDto,
): Promise<ScopeAuditLogDto> {
  return request(`/scope-requests/new`, 'post', data);
}

import type { SchemaForm } from '@/pages/templates/TemplateForm';
import { request } from '@/request';

export function saveTemplate(data: SchemaForm): Promise<void> {
  return request('/templates', 'post', data);
}

export function deleteTemplate(id: string): Promise<void> {
  return request(`/templates/${id}`, 'delete');
}

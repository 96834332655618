import classNames from 'classnames';
import type { ComponentProps, PropsWithChildren } from 'react';

import style from './index.module.css';

export type ContainerProps = ComponentProps<'div'> &
  PropsWithChildren<{
    size?: 'small' | 'medium' | 'large' | 'x-large';
    centered?: boolean;
    stretch?: boolean;
  }>;

export default function Container({
  size = 'large',
  centered = false,
  stretch = false,
  className,
  ...props
}: ContainerProps): JSX.Element {
  return (
    <div
      className={classNames(
        { [style['Container--centered']]: centered },
        { [style['Container--stretch']]: stretch },
        [style['Container'], style[`Container--size-${size}`], className],
      )}
      {...props}
    />
  );
}

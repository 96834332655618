import { DATE_MATCHER, TIME_MATCHER } from '@portals/shared/common/matchers';
import { INVALID_DATE, INVALUD_TIME } from '@portals/shared/common/messages';
import { z } from 'zod';

export const incidentUpdateInputSchema = z.object({
  id: z.string().optional(),
  description: z.string(),
  reportedAtDate: z.string().regex(DATE_MATCHER, INVALID_DATE),
  reportedAtTime: z.string().regex(TIME_MATCHER, INVALUD_TIME),
});

export const incidentInputSchema = z.object({
  id: z.string().optional(),
  type: z.enum(['incident', 'maintenance']),
  apiIds: z.string().min(1).array().min(1),
  severity: z.string(),
  description: z.string(),
  reportedAtDate: z.string().regex(DATE_MATCHER, INVALID_DATE),
  reportedAtTime: z.string().regex(TIME_MATCHER, INVALUD_TIME),
  resolvedAtDate: z
    .string()
    .regex(DATE_MATCHER, INVALID_DATE)
    .optional()
    .or(z.literal('')),
  resolvedAtTime: z
    .string()
    .regex(TIME_MATCHER, INVALUD_TIME)
    .optional()
    .or(z.literal('')),
  updates: z.array(incidentUpdateInputSchema),
});

export type IncidentUpdateFormData = z.infer<typeof incidentUpdateInputSchema>;
export type IncidentFormData = z.infer<typeof incidentInputSchema>;

export const DEFAULT_INCIDENT: Partial<IncidentFormData> = {
  apiIds: [],
  description: '',
  type: 'incident',
  severity: '1',
  updates: [],
};

export const DEFAULT_INCIDENT_UPDATE: Partial<IncidentUpdateFormData> = {
  description: '',
};

import { Drawer, H1, H3, Link, P, Pagination, Space } from '@dnb/eufemia';
import type { FeedbackDto } from '@portals/shared/admin/FeedbackDto';
import { groupBy } from '@portals/shared-frontend/utils';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDatetime } from '@/utils';

import style from './FeedbackList.module.css';

/**
 * Show feedback for a single page
 */

const FEEDBACK_PER_PAGE = 10;

interface FeedbackShowProps {
  feedback: FeedbackDto[];
}

function FeedbackShow({ feedback }: FeedbackShowProps) {
  const [page, setPage] = useState(1);

  const feedbackWithMessage = useMemo(
    () => feedback.filter(({ message }) => !!message),
    [feedback],
  );

  const numberOfPages = feedbackWithMessage
    ? Math.ceil(feedbackWithMessage.length / FEEDBACK_PER_PAGE)
    : 1;

  const feedbackOnCurrentPage = useMemo(
    () =>
      feedbackWithMessage.slice(
        (page - 1) * FEEDBACK_PER_PAGE,
        page * FEEDBACK_PER_PAGE,
      ),
    [feedbackWithMessage, page],
  );

  return (
    <Pagination
      bottom={false}
      current_page={page}
      on_change={({ pageNumber }) => setPage(pageNumber)}
      page_count={numberOfPages}
    >
      <div className={style['Feedback']}>
        {feedbackOnCurrentPage.length > 0 ? (
          feedbackOnCurrentPage.map((feedback) => (
            <Space className={style['Feedback-message']} key={feedback.id}>
              <H3 size="small">{humanDatetime(feedback.createdAt)}</H3>
              <P top="small">{feedback.message || <i>No message</i>}</P>
            </Space>
          ))
        ) : (
          <P>
            <i>No feedback</i>
          </P>
        )}
      </div>
    </Pagination>
  );
}

/**
 * List all pages
 */

interface FeedbackRow {
  path: string;
  helpful: string;
  latest: number;
}

const COLUMNS: Column<FeedbackRow>[] = [
  {
    header: 'Page',
    attribute: 'path',
    render: ({ path }) => (
      <Link href={`https://developer.dnb.no${path}`} target="_blank">
        {path}
      </Link>
    ),
  },
  { header: 'Helpful %', attribute: 'helpful' },
  {
    header: 'Latest message',
    attribute: 'latest',
    render: ({ latest }) => humanDatetime(latest),
  },
];

export default function FeedbackList(): JSX.Element {
  const [activePath, setActivePath] = useState<string | null>(null);

  const { data: feedback, isValidating: feedbackLoading } =
    useSWR<FeedbackDto[]>('/feedback');

  const feedbackByPath = useMemo(
    () => groupBy(feedback ?? [], 'path'),
    [feedback],
  );

  const rows = useMemo(() => {
    if (!feedback) {
      return [];
    }

    return Object.entries(feedbackByPath).map<FeedbackRow>(
      ([path, feedback]) => {
        const messageTimestamps = feedback
          .filter(({ message }) => !!message)
          .map(({ createdAt }) => new Date(createdAt).valueOf());

        const helpfulRatio =
          feedback.reduce((sum, { helpful }) => sum + (helpful ? 0 : 1), 0) /
          feedback.length;

        return {
          path,
          latest: Math.max(...messageTimestamps),
          helpful: Math.round(helpfulRatio * 100).toString(),
        };
      },
    );
  }, [feedback, feedbackByPath]);

  const isLoading = feedbackLoading;
  const activeFeedback = activePath ? feedbackByPath[activePath] : null;

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>
      <Drawer
        onClose={() => setActivePath(null)}
        openState={!!activePath}
        title={activePath}
        triggerAttributes={{
          hidden: true,
        }}
      >
        <FeedbackShow feedback={activeFeedback ?? []} />
      </Drawer>
      <H1 top="large">Feedback</H1>
      <DataTable
        columns={COLUMNS}
        data={rows}
        defaultSortKey="path"
        filterBy={['path', 'latest']}
        onShow={(row) => setActivePath(row.path)}
      />
    </>
  );
}

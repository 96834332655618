import { object, string } from 'zod';

import type { TeamDto } from './TeamDto';

export interface ProjectDto {
  id: string;
  name: string;
  description: string | null;
  ciamUatAppId: string | null;
  ciamProdAppId: string | null;
}
export interface ProjectWithTeamsDto extends ProjectDto {
  teams: TeamDto[];
}

export const projectForm = object({
  name: string()
    .regex(/^[\d._a-z-]+$/, {
      message: 'Project name supports chars a-z, 0-9, _, -, and .',
    })
    .min(2)
    .max(120),
  description: string().max(400).optional().nullable(),
  ciamUatAppId: string().uuid().optional().nullable(),
  ciamProdAppId: string().uuid().optional().nullable(),
});

export const projectTeamRelationsForm = object({
  projectId: string(),
  teamId: string(),
});

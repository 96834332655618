import { Button, Hr, ToggleButton } from '@dnb/eufemia';
import styled from '@emotion/styled';
import { AccessTypes } from '@portals/shared/admin/ApiDto';
import { Link } from 'react-router-dom';
export type BOOLEAN_FILTER_VALUES = 'yes' | 'no' | 'all';

export type CLASSIFICATION_FILTER_VALUES =
  | 'system'
  | 'domain'
  | 'experience'
  | 'public'
  | 'partner'
  | 'technical'
  | 'all';

export type STAGE_FILTER_VALUES =
  | 'review'
  | 'launched'
  | 'upcoming'
  | 'lab'
  | 'deprecated'
  | 'unpublished'
  | 'all';

export type Key =
  | 'attachableLiveMode'
  | 'attachableTestMode'
  | 'publicApi'
  | 'stage'
  | 'classification'
  | 'accessType'
  | 'servicenowIntegration';

export type Filters = {
  attachableLiveMode: BOOLEAN_FILTER_VALUES;
  attachableTestMode: BOOLEAN_FILTER_VALUES;
  publicApi: BOOLEAN_FILTER_VALUES;
  stage: STAGE_FILTER_VALUES[];
  classification: CLASSIFICATION_FILTER_VALUES[];
  accessType: AccessTypes[];
  servicenowIntegration: BOOLEAN_FILTER_VALUES;
};

type FilterObject = {
  name: string;
  value:
    | BOOLEAN_FILTER_VALUES
    | STAGE_FILTER_VALUES
    | CLASSIFICATION_FILTER_VALUES
    | AccessTypes;
};

const booleanFilters: FilterObject[] = [
  { name: 'Both', value: 'all' },
  { name: 'Yes', value: 'yes' },
  { name: 'No', value: 'no' },
];

const classificationFilters: FilterObject[] = [
  { name: 'System', value: 'system' },
  { name: 'Domain', value: 'domain' },
  { name: 'Experience', value: 'experience' },
  { name: 'Public', value: 'public' },
  { name: 'Partner', value: 'partner' },
  { name: 'Technical', value: 'technical' },
];

const stageFilters: FilterObject[] = [
  { name: 'Launched', value: 'launched' },
  { name: 'Upcoming', value: 'upcoming' },
  { name: 'Lab', value: 'lab' },
  { name: 'Deprecated', value: 'deprecated' },
  { name: 'Unpublished', value: 'unpublished' },
  { name: 'In Review', value: 'review' },
];

const accessTypeFilters: FilterObject[] = [
  { name: 'System to System', value: AccessTypes.O_AUTH_SYSTEM_TO_SYSTEM },
  { name: 'Api keys', value: AccessTypes.API_KEYS },
];

type FilterGroup = {
  label: string;
  key: Key;
  variant: 'radio' | 'checkbox';
  multiselect: boolean;
  filters: FilterObject[];
};

const all: FilterGroup[] = [
  {
    label: 'Attachable in test mode',
    key: 'attachableTestMode',
    variant: 'radio',
    multiselect: false,
    filters: booleanFilters,
  },
  {
    label: 'Attachable in live mode',
    key: 'attachableLiveMode',
    variant: 'radio',
    multiselect: false,
    filters: booleanFilters,
  },
  {
    label: 'Access Type',
    key: 'accessType',
    variant: 'checkbox',
    multiselect: true,
    filters: accessTypeFilters,
  },
  {
    label: 'ServiceNow integration',
    key: 'servicenowIntegration',
    variant: 'radio',
    multiselect: false,
    filters: booleanFilters,
  },
  {
    label: 'Public API',
    key: 'publicApi',
    variant: 'radio',
    multiselect: false,
    filters: booleanFilters,
  },
  {
    label: 'Stage',
    key: 'stage',
    variant: 'checkbox',
    multiselect: true,
    filters: stageFilters,
  },
  {
    label: 'Classification',
    key: 'classification',
    variant: 'checkbox',
    multiselect: true,
    filters: classificationFilters,
  },
];

type ApiFilterProps = {
  onFilterChange: (newValue: string, key: Key) => void;
  filter: Filters;
};

export default function ApiFilters({ onFilterChange, filter }: ApiFilterProps) {
  return (
    <>
      {all.map((group, index) => {
        return (
          <ToggleButton.Group
            bottom="medium"
            key={`${group.key}-${group.label}`}
            label={group.label}
            label_direction="vertical"
            multiselect={group.multiselect}
            on_change={({ value, values }) =>
              group.multiselect
                ? onFilterChange(values, group.key)
                : onFilterChange(value, group.key)
            }
            top={index == 0 ? 0 : 'small'}
            value={filter[`${group.key}`]}
            values={filter[`${group.key}`]}
            variant={group.variant}
          >
            {group.filters.map(({ name, value }) => (
              <ToggleButton key={`${group.key}-${value}`} value={value}>
                {name}
              </ToggleButton>
            ))}
          </ToggleButton.Group>
        );
      })}
      <Divider />
      <Button
        element={Link}
        icon="chevron_right"
        left
        text="Show OAuth apis without SN integration"
        to="/apis/without-sn"
        variant="secondary"
      />
    </>
  );
}

const Divider = styled(Hr)`
  color: var(--color-black-8);
  padding-bottom: var(--spacing-small);
`;

import type {
  AppUpdateFormDto,
  DeleteApiAccessInputDto,
  DeleteScopeAccessInputDto,
} from '@portals/shared/admin/AppDto';

import { request } from '@/request';

export function deleteApiAccess(data: DeleteApiAccessInputDto): Promise<void> {
  return request(`/apps/apis/access`, 'delete', data);
}

export function deleteScopeAccess(
  data: DeleteScopeAccessInputDto,
): Promise<void> {
  return request(`/apps/scopes/access`, 'delete', data);
}

export function deleteApiKey(appId: string, apiKeyId: string): Promise<void> {
  return request(`/apps/${appId}/apiKeys/${apiKeyId}`, 'delete');
}

export function deleteClient(clientId: string): Promise<void> {
  return request(`/apps/clients/${clientId}`, 'delete');
}

export function updateApp(id: string, data: AppUpdateFormDto): Promise<void> {
  return request(`/apps/${id}`, 'patch', data);
}

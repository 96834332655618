import { Button, H1, H2, Link, P, Space, Tabs } from '@dnb/eufemia';
import { edit as EditIcon } from '@dnb/eufemia/icons';
import type { ContentWithChildrenDto } from '@portals/shared/admin/ContentDto';
import useAsync from '@portals/shared-frontend/hooks/useAsync';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { deleteContentByPath } from '@/api/content';
import BackButton from '@/components/BackButton';
import DataTable from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import NoData from '@/components/NoData';
import DeleteDialog from '@/pages/content/components/DeleteDialog';
import { humanDate } from '@/utils';

import RecoverContentTable from './RecoverContentTable';
import { toRows } from './schemas';
import { COLUMNS } from './schemas';
import UnstructuredContentTable from './UnstructuredContentTable';

import style from './ContentList.module.css';

enum TabKey {
  content = 'content',
  recoveredContent = 'recovered-content',
  unstructuredContent = 'unstructured-content',
}

const TABS = [
  { title: 'Content', key: TabKey.content },
  { title: 'Recovered content', key: TabKey.recoveredContent },
  { title: 'Unstructured content', key: TabKey.unstructuredContent },
];

export default function ContentList(): JSX.Element {
  const [activeTab, setActiveTab] = useState<TabKey>(TabKey.content);
  const navigate = useNavigate();

  const {
    data: content,
    isValidating: loadingContent,
    mutate,
  } = useSWR<ContentWithChildrenDto[]>('/content');

  const onDeleteContent = useAsync(
    async (id: string) => {
      await deleteContentByPath({ id: id });
      mutate();
    },
    [mutate],
  );

  if (loadingContent || !content) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>
      <H1 top="large">Content</H1>
      <Tabs
        bottom="large"
        data={TABS}
        on_change={({ key }) => setActiveTab(key)}
        selected_key={activeTab}
      />

      {activeTab === TabKey.content && (
        <>
          <Button
            bottom="medium"
            element={RouterLink}
            icon="add"
            icon_position="left"
            to="/content/new"
            variant="secondary"
          >
            Add page
          </Button>

          {content.map((c) => (
            <Space
              bottom="medium"
              className={style['TableContainer']}
              key={c.id}
            >
              <Link element={RouterLink} to={`/content${c.path}`}>
                <H2>{c.title}</H2>
              </Link>
              <P bold size="small" top="x-small">
                {c.path}
              </P>
              <P top="x-small">Created: {humanDate(c.createdAt)}</P>

              <Space className={style['ButtonsContainer']} top="small">
                <Button
                  icon="add"
                  icon_position="left"
                  on_click={() => navigate(`/content/edit?parentId=${c.id}`)}
                  variant="tertiary"
                >
                  Add sub-page
                </Button>
                <Button
                  element={RouterLink}
                  icon={EditIcon}
                  icon_position="left"
                  to={`/content/edit/${c.id}`}
                  variant="tertiary"
                >
                  Edit page
                </Button>

                <DeleteDialog
                  id={c.id}
                  onDelete={onDeleteContent.execute}
                  title={c.title}
                  variant="tertiary"
                />
              </Space>

              {c.children.length === 0 ? (
                <NoData message="No sub-pages available" />
              ) : (
                <DataTable
                  columns={COLUMNS}
                  data={toRows(c.children)}
                  defaultSortKey="sortIndex"
                  onEdit={({ id }) =>
                    navigate(`/content/edit?parentId=${c.id}&contentId=${id}`)
                  }
                />
              )}
            </Space>
          ))}
        </>
      )}

      {activeTab === TabKey.recoveredContent && <RecoverContentTable />}
      {activeTab === TabKey.unstructuredContent && <UnstructuredContentTable />}
    </>
  );
}

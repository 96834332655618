import { Breadcrumb, Button, Div, H1, H2, Link, P, Space } from '@dnb/eufemia';
import { edit as EditIcon } from '@dnb/eufemia/icons';
import type {
  ContentDto,
  ContentWithChildrenDto,
} from '@portals/shared/admin/ContentDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { deleteContentByPath } from '@/api/content';
import DataTable from '@/components/DataTable';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import NoData from '@/components/NoData';
import DeleteDialog from '@/pages/content/components/DeleteDialog';
import { humanDate } from '@/utils';

import { COLUMNS, toRows } from './schemas';

import style from './ContentList.module.css';

function goBack(parent: string) {
  const steps = parent.split('/');
  steps.pop();
  return steps.join('/');
}

const backPaths = (parent: string) => {
  const paths = parent.split('/');
  const bPaths = [];
  let previousPath = '';
  for (const [i, path] of paths.entries()) {
    if (i != 0) {
      previousPath += `/${path}`;
      bPaths.push({
        href: `/content${previousPath}`,
        text: path,
        icon: 'chevron_right',
      });
    }
  }
  bPaths.unshift({ href: '/content', text: 'Content', icon: 'chevron_right' });
  return bPaths;
};

export default function ContentListByPath(): JSX.Element {
  const navigate = useNavigate();
  const path = window.location.pathname;
  const parent = path.replace('/content', '');
  const {
    data: content,
    isValidating: loadingContent,
    mutate: mutateContent,
  } = useSWR<ContentWithChildrenDto[]>(window.location.pathname);

  const { data: parentContent, isValidating: loadingParent } =
    useSWR<ContentDto>(`/content/parent${parent}`);

  const onDeleteContent = useAsync(
    async (id: string) => {
      if (parentContent) {
        await deleteContentByPath({ id: id });

        navigate(`/content${goBack(parent)}`);
        mutateContent();
      }
    },
    [mutateContent, navigate, parent, parentContent],
  );

  const isLoading = loadingParent || loadingContent;
  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Breadcrumb data={backPaths(parent)} />

      <Space bottom="large" top="large">
        <H1>{parentContent?.title}</H1>
        <P bold size="small">
          {parentContent?.path}
        </P>
        <Button
          icon="add"
          icon_position="left"
          on_click={() =>
            navigate(`/content/edit?parentId=${parentContent?.id}`)
          }
          right="small"
          top="medium"
          variant="secondary"
        >
          Add sub-page
        </Button>
        <Button
          icon={EditIcon}
          icon_position="left"
          on_click={() => {
            navigate(
              `/content/edit?parentId=${parentContent?.parentId}&contentId=${parentContent?.id}`,
            );
          }}
          right="small"
          top="medium"
          variant="secondary"
        >
          Edit page
        </Button>
        {parentContent && (
          <DeleteDialog
            id={parentContent.id}
            onDelete={onDeleteContent.execute}
            title={parentContent.title}
            variant="secondary"
          />
        )}
      </Space>
      {onDeleteContent.waiting && <LoadingModal />}

      {content?.length === 0 ? (
        <Space className={style['NoContentContainer']}>
          <NoData message="This page has no content." />
        </Space>
      ) : (
        content?.map((c) => (
          <Space bottom="medium" className={style['TableContainer']} key={c.id}>
            <Link element={RouterLink} to={`/content${c.path}`}>
              <H2>{c.title}</H2>
            </Link>
            <P bold size="small" top="x-small">
              {c.path}
            </P>
            <P top="x-small">{humanDate(c.createdAt)}</P>

            <Space className={style['ButtonsContainer']} top="small">
              <Button
                icon="add"
                icon_position="left"
                on_click={() => {
                  navigate(`/content/edit?parentId=${c?.id}`);
                }}
                variant="tertiary"
              >
                Add content
              </Button>

              <Button
                icon={EditIcon}
                icon_position="left"
                on_click={() => {
                  navigate(
                    `/content/edit?parentId=${parentContent?.id}&contentId=${c.id}`,
                  );
                }}
                variant="tertiary"
              >
                Edit page
              </Button>

              <DeleteDialog
                id={c.id}
                onDelete={onDeleteContent.execute}
                title={c.title}
                variant="tertiary"
              />
            </Space>
            {c.children.length === 0 ? (
              <NoData message="No subpages available" />
            ) : (
              <DataTable
                barContent={<Div />}
                columns={COLUMNS}
                data={toRows(c.children)}
                defaultSortKey="sortIndex"
                onEdit={({ id, parentId }) => {
                  navigate(
                    `/content/edit?parentId=${parentId}&contentId=${id}`,
                  );
                }}
              />
            )}
          </Space>
        ))
      )}
    </>
  );
}

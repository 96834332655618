import { Div, Img, P } from '@dnb/eufemia';

import style from './index.module.css';

import NoDataFly from './NoDataFly.svg?url';
interface NoDataProps {
  message: string;
}

export default function NoData({ message }: NoDataProps) {
  return (
    <Div className={style['Container']}>
      <Img alt="A fly and no data" src={NoDataFly} width={40} />
      <P top="medium">{message}</P>
    </Div>
  );
}

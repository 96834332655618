import { Anchor, H1 } from '@dnb/eufemia';
import type { TeamWithOrganizationDto } from '@portals/shared/admin/TeamDto';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import type { Column } from '@/components/DataTable';
import DataTable from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

interface TeamRow {
  id: string;
  name: string;
  organization?: string;
  hasLiveAccess: string;
  createdAt: Date;
}

const COLUMNS: Column<TeamRow>[] = [
  {
    header: 'Name',
    attribute: 'name',
    render: ({ name, id }) => (
      <Anchor element={Link} to={`/teams/${id}`}>
        {name}
      </Anchor>
    ),
  },
  { header: 'Organization', attribute: 'organization' },
  { header: 'Has live access', attribute: 'hasLiveAccess' },
  {
    header: 'Created at',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDate(createdAt),
  },
];

export default function TeamList(): JSX.Element {
  const navigate = useNavigate();
  const { data: teams, isValidating: teamsLoading } =
    useSWR<TeamWithOrganizationDto[]>('/teams');

  const rows = useMemo(() => {
    if (!teams) {
      return [];
    }
    return teams.map<TeamRow>((team) => ({
      id: team.id,
      name: team.name,
      organization: team.organization?.name,
      hasLiveAccess: team.hasLiveAccess ? 'Yes' : 'No',
      createdAt: new Date(team.createdAt),
    }));
  }, [teams]);

  if (teamsLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>
      <H1 bottom="x-large">Teams</H1>
      <DataTable
        columns={COLUMNS}
        data={rows}
        defaultSortKey="organization"
        filterBy={['name', 'organization']}
        onShow={({ id }) => navigate(id)}
      />
    </>
  );
}

import { Button, FormRow, FormSet, H1, Input, Section } from '@dnb/eufemia';
import type { TagDto } from '@portals/shared/admin/TagDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { string, z } from 'zod';

import { createTag, updateTag } from '@/api/tags';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';

const tagFormSchema = z.object({
  title: string().min(1),
  slug: string().min(1),
});

type TagFormData = z.output<typeof tagFormSchema>;

const DEFAULT_TAG: TagFormData = {
  title: '',
  slug: '',
};

export default function TagForm(): JSX.Element {
  const { tagId } = useParams();
  const navigate = useNavigate();
  const {
    controller: { setValues },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(tagFormSchema, DEFAULT_TAG);

  const { data: tag, isValidating: tagLoading } = useSWR<TagDto>(
    tagId ? `/tags/${tagId}` : null,
  );

  const title = tag ? 'Edit tag' : 'Add tag';
  const isLoading = tagLoading;

  useEffect(() => {
    if (tag) {
      setValues(tag);
    }
  }, [tag, setValues]);

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        if (tagId) {
          await updateTag(tagId, data);
        } else {
          await createTag(data);
        }
        navigate('..');
      },
      [navigate, tagId],
    ),
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/tags">Tags</BackButton>

      <Container centered size="small">
        {submitting && <LoadingModal />}

        <H1 bottom={false} top="large">
          {title}
        </H1>
        <FormSet label_direction="vertical" on_submit={onSubmit}>
          <Section spacing="x-large" style_type="white">
            <FormRow>
              <Input label="Name" size={40} {...register.input('title')} />
            </FormRow>
            <FormRow top="medium">
              <Input label="Slug" size={40} {...register.input('slug')} />
            </FormRow>
          </Section>
          <Section spacing="large" style_type="divider">
            <Button
              disabled={submitting}
              icon="save"
              icon_position="left"
              type="submit"
            >
              Save
            </Button>
          </Section>
        </FormSet>
      </Container>
    </>
  );
}

import type { Filters } from './UserFilters';

export type Tab = 'all' | 'expired' | 'about-to-expire';
export const tabs = [
  {
    title: 'All users',
    key: 'all',
  },
  {
    title: '30 days to expired',
    key: 'about-to-expire',
  },
  {
    title: 'Expired',
    key: 'expired',
  },
];

export interface UserRow {
  id: string;
  name: string;
  email: string;
  createdAt: Date;
  lastLoggedIn: Date | null;
  hasLiveAccess: boolean;
}

export const THREE_YEARS = 1095;
export const TWO_YEARS_11_MONTHS = 1065;

export function getDateXDaysBackInTime(daysBackInTime: number) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - daysBackInTime);

  // Format the date as "YYYY-MM-DD"
  return currentDate.toISOString().slice(0, 10);
}

export const DEFAULT_FILTERS: Filters = {
  excludeUserGroup: [],
  loginFrom: '',
  registeredBefore: '',
  queryString: '',
  liveModeFilter: '',
};

import type {
  AppApiEntitlementRequestDetailsResponseDto,
  EntitlementRequestInputDto,
} from '@portals/shared/admin/AppApiEntitlementDto';

import { request } from '@/request';

export function handleEntitlementRequest(
  id: string,
  data: EntitlementRequestInputDto,
): Promise<AppApiEntitlementRequestDetailsResponseDto> {
  return request(`/entitlement-requests/${id}`, 'post', data);
}

import { H1, H2, H3, Icon, Img, P, Section, Space } from '@dnb/eufemia';
import { chevron_right_medium as ChevronRightIcon } from '@dnb/eufemia/icons';
import { Dashboard } from '@portals/shared-frontend/components';
import classnames from 'classnames';
import { type PropsWithChildren, useMemo } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import Container from '@/components/Container';
import LoadingPage from '@/components/LoadingPage';

import style from './Landing.module.css';

import DeveloperIllustration from '@/assets/illustrations/Developer.svg?url';

type ShortcutProps = PropsWithChildren<{
  to: string;
  title: string;
}>;

function Shortcut({ to, title, children }: ShortcutProps): JSX.Element {
  return (
    <Link
      className={classnames(['dnb-anchor--no-style', 'dnb-tab-focus'])}
      style={{ textDecoration: 'none', color: 'black' }}
      to={to}
    >
      <div className={classnames(['dnb-drop-shadow', style['Shortcut']])}>
        <H3>{title}</H3>
        <P>{children}</P>
        <Icon icon={ChevronRightIcon} />
      </div>
    </Link>
  );
}

export default function Landing(): JSX.Element {
  const { data, isValidating: loadingDashboard } = useSWR(
    `/dashboard/developer-portal-api`,
  );

  const dashboardData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data;
  }, [data]);

  return (
    <Container centered size="x-large">
      <H1 top="large">Admin Portal</H1>

      {loadingDashboard && <LoadingPage />}
      <Dashboard data={dashboardData} />

      <Section spacing="x-large" style_type="sand-yellow">
        <div className={style['Jumbotron']}>
          <Img alt="Developer" right="large" src={DeveloperIllustration} />
          <H2>What is the Admin Portal?</H2>
          <P top="medium">
            The Admin Portal is where you can create and manage APIs that appear
            in the Developer Portal. To get started, you first need to set up a
            framework to organize your documentation. Start by creating an API
            for each swagger file you want to upload. Be sure to set the proper
            access control limitations. After your APIs are created, you can
            publish your documentation.
          </P>
        </div>
      </Section>
      <Section spacing="x-large" style_type="white">
        <H2>Shortcuts</H2>
        <Space className={style['Shortcuts']} top="medium">
          <Shortcut title="Add incident" to="/api-incidents/new">
            If something has gone wrong with your API and you need to notify
            your customers, create an incident here. It will appear on the
            status page.
          </Shortcut>
          <Shortcut title="Publish your API" to="/apis/new">
            Find the documentation for our API that you can use to upload your
            documentation here.
          </Shortcut>
        </Space>
      </Section>
    </Container>
  );
}

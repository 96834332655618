import { format } from 'date-fns';

/**
 * Apply formatting to date
 */

export function _maybeFormatDate(
  date: Date | string | number | null,
  formatting: string,
): string {
  if (date == null) {
    return 'N/A';
  }

  if (typeof date === 'string' || typeof date === 'number') {
    return format(new Date(date), formatting);
  }

  return format(date, formatting);
}

/**
 * Human date
 */

export function humanDate(date: Date | string | number | null): string {
  return _maybeFormatDate(date, 'dd.MM.yyyy');
}

/**
 * Human datetime
 */

export function humanDatetime(date: Date | number | string | null): string {
  return _maybeFormatDate(date, 'dd.MM.yyyy HH:mm');
}

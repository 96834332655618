import { Button, Dialog, P } from '@dnb/eufemia';
import { trash as TrashIcon } from '@dnb/eufemia/icons';
import { useState } from 'react';

import LoadingModal from '../LoadingModal';

type DeleteDialogProps = {
  onDelete: () => void;
  text: string;
  title: string;
  loading?: boolean;
  triggerVariant?: 'secondary' | 'tertiary' | 'primary' | 'signal';
};
export default function DeleteDialog({
  text,
  title,
  loading,
  onDelete,
  triggerVariant = 'secondary',
}: DeleteDialogProps) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {loading && <LoadingModal />}
      <Button
        icon={TrashIcon}
        icon_position="left"
        on_click={() => setOpen(true)}
        variant={triggerVariant}
      >
        Delete
      </Button>
      {open && (
        <Dialog
          confirmText="Delete"
          confirmType="warning"
          icon={TrashIcon}
          onClose={() => setOpen(false)}
          onConfirm={onDelete}
          onDecline={() => setOpen(false)}
          openState
          right="small"
          title={title}
          triggerAttributes={{
            hidden: true,
          }}
          variant="confirmation"
        >
          <P>{text}</P>
        </Dialog>
      )}
    </>
  );
}

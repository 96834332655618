import { H1, Tabs } from '@dnb/eufemia';
import type { ScopeAuditLogDto } from '@portals/shared/admin/ScopeAuditLogDto';
import type { ScopeFromAllSourcesDto } from '@portals/shared/admin/ScopeDto';
import type { ScopeRequestWithApiAndOwnerDto } from '@portals/shared/admin/ScopeRequestDto';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';

import ScopeAuditLogsTable from './components/ScopeAuditLogsTable';
import ScopeRequestsTable from './components/ScopeRequestsTable';
import ScopesTable from './components/ScopesTable';

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => navigate(to, { replace: true }), [navigate, to]);
  return null;
};

enum TabKey {
  pending = 'pending',
  history = 'history',
  active = 'active',
}

const TABS = [
  { title: 'Scope Request', key: TabKey.pending },
  { title: 'Scope history', key: TabKey.history },
  { title: 'Scope overview', key: TabKey.active },
];

export default function ScopesList(): JSX.Element {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const { data: scopes, isValidating: isScopeRequestLoading } =
    useSWR<ScopeRequestWithApiAndOwnerDto[]>('/scope-requests');
  const { data: scopeAuditLogs, isValidating: isAuditLogLoading } =
    useSWR<ScopeAuditLogDto[]>('/scope-audit-logs');
  const { data: activeScopes, isValidating: isActiveScopeLoading } =
    useSWR<ScopeFromAllSourcesDto[]>('/scopes');

  return (
    <>
      <BackButton to="/">Home</BackButton>
      <H1 top="large">Scopes</H1>
      <Tabs
        bottom="large"
        data={TABS}
        on_change={({ key }) => navigate(`/scopes/${key}`)}
        selected_key={pathname.split('/')[2]}
      />

      <Routes>
        <Route element={<Redirect to="pending" />} index />
        <Route
          element={
            <ScopeRequestsTable
              loading={isScopeRequestLoading}
              scopeRequests={scopes || []}
            />
          }
          path="/pending"
        />
        <Route
          element={
            <ScopeAuditLogsTable
              loading={isAuditLogLoading}
              scopeAuditLogs={scopeAuditLogs || []}
            />
          }
          path="/history"
        />
        <Route
          element={
            <ScopesTable
              loading={isActiveScopeLoading}
              scopes={activeScopes || []}
            />
          }
          path="/active"
        />
      </Routes>
    </>
  );
}

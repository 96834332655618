import '@dnb/eufemia/style';

import { GlobalError, GlobalStatus, Icon, Logo } from '@dnb/eufemia';
import {
  cloud_transfer_medium as CloudTransferIcon,
  credit_note_medium as CreditNoteIcon,
  email_medium as EmailIcon,
  exclamation_circled_medium as ExclamationIcon,
  globe_medium as GlobeIcon,
  megaphone_medium as MegaphoneIcon,
  office_buildings_medium as OfficBuildingIcon,
  padlock_medium as PadlockIcon,
  people_2_medium as People2Icon,
  person_medium as PersonIcon,
  split_medium as SplitIcon,
  support_medium as SupportIcon,
  tag_medium as TagIcon,
  widget_android_medium as WidgetAndroidIcon,
} from '@dnb/eufemia/icons';
import { ErrorContext } from '@portals/shared-frontend/hooks';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  NavLink,
  type NavLinkProps,
  Outlet,
  useLocation,
} from 'react-router-dom';

import style from './App.module.css';

interface IconNavLinkProps extends NavLinkProps {
  icon: React.FC;
}

const IconNavLink = ({ icon, ...props }: IconNavLinkProps) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        classNames({
          [style['NavLink']]: true,
          [style['ActiveNavLink']]: isActive,
          ['dnb-tab-focus']: true,
        })
      }
    >
      <Icon icon={icon} right="x-small" size={22} />
      {props.children}
    </NavLink>
  );
};

function App() {
  const { pathname } = useLocation();
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    window.scrollTo(0, 0);
    setError(null);
  }, [pathname]);

  if (error) {
    if (ApiError.isApiError(error)) {
      return (
        <GlobalError status={error.status + ''} text={error.body.message} />
      );
    } else {
      return <GlobalError status="500" />;
    }
  }

  return (
    <div className={style['App']}>
      <header className={style['Bar']}>
        <NavLink to="/">
          <Logo size={48} />
        </NavLink>
      </header>
      <div className={style['Content']}>
        <nav className={style['Navigation']}>
          <ul>
            <li>
              <IconNavLink icon={CloudTransferIcon} to="/apis">
                APIs
              </IconNavLink>
              <IconNavLink icon={WidgetAndroidIcon} to="/projects">
                Projects
              </IconNavLink>
              <IconNavLink icon={WidgetAndroidIcon} to="/apps">
                Apps
              </IconNavLink>
              <IconNavLink icon={ExclamationIcon} to="/api-incidents">
                Status
              </IconNavLink>
              <IconNavLink icon={EmailIcon} to="/subscribers">
                Subscribers
              </IconNavLink>
              <IconNavLink icon={MegaphoneIcon} to="/news">
                News
              </IconNavLink>
              <IconNavLink icon={SupportIcon} to="/feedback">
                Feedback
              </IconNavLink>
              <IconNavLink icon={PersonIcon} to="/users">
                Users
              </IconNavLink>
              <IconNavLink icon={People2Icon} to="/teams">
                Teams
              </IconNavLink>
              <IconNavLink icon={OfficBuildingIcon} to="/organizations">
                Organizations
              </IconNavLink>
              <IconNavLink icon={PadlockIcon} to="/api-grants">
                Grants
              </IconNavLink>
              <IconNavLink icon={SplitIcon} to="/feature-flags">
                Feature flags
              </IconNavLink>
              <IconNavLink icon={TagIcon} to="/tags">
                Tags
              </IconNavLink>
              <IconNavLink icon={PadlockIcon} to="/templates">
                Templates
              </IconNavLink>
              <IconNavLink icon={CreditNoteIcon} to="/content">
                Content
              </IconNavLink>
              <IconNavLink icon={GlobeIcon} to="/scopes">
                Scopes
              </IconNavLink>
              <IconNavLink icon={PadlockIcon} to="/entitlements">
                Entitlements
              </IconNavLink>
            </li>
          </ul>
        </nav>
        <main className={style['Main']}>
          <div className={style['Main-status']}>
            <GlobalStatus no_animation />
          </div>
          <ErrorContext.Provider value={[error, setError]}>
            <div className={style['Main-content']}>
              <Outlet />
            </div>
          </ErrorContext.Provider>
        </main>
      </div>
    </div>
  );
}

export default App;

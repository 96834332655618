import { Button, Dialog, Div, Drawer } from '@dnb/eufemia';
import {
  email as EmailIcon,
  filter as FilterIcon,
  trash as TrashIcon,
} from '@dnb/eufemia/icons';

import UserFilters, { type Filters } from '@/pages/users/UserFilters';

import type { Tab } from '../constants';

type BarContentProps = {
  tab: Tab;
  filter: Filters;
  numberOfUsers: number;
  openDeleteDialog: () => void;
  onApply: (newFilter: Filters) => void;
  onDownloadUsers: () => void;
  onNotifyUsers: () => void;
};
export default function BarContent({
  tab,
  filter,
  numberOfUsers,
  openDeleteDialog,
  onApply,
  onDownloadUsers,
  onNotifyUsers,
}: BarContentProps) {
  const FilterDrawer = () => (
    <Drawer
      hideCloseButton
      right="small"
      title="Filters"
      triggerAttributes={{
        text: 'Filters',
        variant: 'secondary',
        icon: FilterIcon,
      }}
    >
      {({ close }) => (
        <UserFilters
          close={close}
          filter={filter}
          hideDateFilter={tab !== 'all'}
          onApply={onApply}
        />
      )}
    </Drawer>
  );
  if (tab === 'expired') {
    return (
      <Div>
        <FilterDrawer />
        <Button
          icon={EmailIcon}
          icon_position="left"
          on_click={onNotifyUsers}
          variant="secondary"
        >
          Notify users about deletion
        </Button>
        <Button
          icon={TrashIcon}
          icon_position="left"
          left="small"
          on_click={openDeleteDialog}
          variant="secondary"
        >
          Delete users
        </Button>
      </Div>
    );
  }

  if (tab === 'about-to-expire') {
    return (
      <Div>
        <FilterDrawer />
        <Button
          icon={EmailIcon}
          icon_position="left"
          on_click={onNotifyUsers}
          variant="secondary"
        >
          Notify users about deletion
        </Button>
      </Div>
    );
  }

  if (tab === 'all') {
    return (
      <Div>
        <FilterDrawer />

        <Dialog
          confirmText="Download"
          description="Are you sure you want to download user details? Please make sure
to delete this file from your PC since it contains customer data!"
          disabled={numberOfUsers === 0}
          icon="download_medium"
          onConfirm={({ close }) => {
            close();
            onDownloadUsers();
          }}
          title="Download users"
          triggerAttributes={{
            text: 'Download users',
            icon: 'download',
          }}
          variant="confirmation"
        />
      </Div>
    );
  }
  return <Div />;
}

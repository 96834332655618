import { Button } from '@dnb/eufemia';
import type { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type BackButtonProps = PropsWithChildren<{
  to: string;
}>;

export default function BackButton({
  to,
  children,
}: BackButtonProps): JSX.Element {
  return (
    <Button
      element={Link}
      icon="arrow_left"
      icon_position="left"
      to={to}
      variant="tertiary"
    >
      {children}
    </Button>
  );
}

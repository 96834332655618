import type {
  CreatePostDto,
  PostDto,
  UpdatePostDto,
} from '@portals/shared/admin/PostDto';

import { request } from '@/request';

export function createPost(data: CreatePostDto): Promise<PostDto> {
  return request<PostDto>('/posts', 'post', data);
}

export function updatePost(id: string, data: UpdatePostDto): Promise<PostDto> {
  return request<PostDto>(`/posts/${id}`, 'patch', data);
}

export function deletePost(id: string) {
  return request(`/posts/${id}`, 'delete');
}

export function sendPost(id: string): Promise<void> {
  return request(`/posts/${id}/send`, 'post');
}

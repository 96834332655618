import type {
  AdvancedContentFormData,
  BasicContentFormData,
  DeleteContent,
} from '@/pages/content/schemas';
import { request } from '@/request';

type InputData = Omit<
  BasicContentFormData | AdvancedContentFormData,
  'sortIndex'
> & {
  sortIndex: number;
};

export function createDefaultContent(data: InputData): Promise<void> {
  return request(`/content/new-default`, 'post', data);
}

export function createContent(data: InputData): Promise<void> {
  return request(`/content/new`, 'post', data);
}

export function deleteContentByPath(data: DeleteContent): Promise<void> {
  return request(`/content`, 'delete', data);
}

export function recoverContent(recoveryId: string): Promise<void> {
  return request(`/content-recovery/${recoveryId}`, 'post');
}

export function deleteRecoveredContent(recoveryId: string): Promise<void> {
  return request(`/content-recovery/${recoveryId}`, 'delete');
}

import { Dialog, P } from '@dnb/eufemia';
import { trash_medium as TrashIcon } from '@dnb/eufemia/icons';
import type { ClientDto } from '@portals/shared/admin/ClientDto';
import { useState } from 'react';

import Card from '@/components/Card';

import style from './index.module.css';

type Client = { clients: ClientDto[]; onDelete: (clientId: string) => void };

export function Clients({ clients, onDelete }: Client): JSX.Element {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  return (
    <>
      {clients.map((client) => (
        <Card
          className={style['ClientCard']}
          direction="horizontal"
          key={client.id}
        >
          <P>Client ID: {client.clientId}</P>
          <Dialog
            confirmText="Delete"
            confirmType="warning"
            declineText="Cancel"
            description="This client will be deleted permanetly from this app."
            icon={TrashIcon}
            onClose={() => {
              setOpenConfirmationModal(false);
            }}
            onConfirm={() => {
              setOpenConfirmationModal(true);
              onDelete(client.id);
            }}
            onOpen={() => setOpenConfirmationModal(true)}
            openState={openConfirmationModal}
            title="Delete client"
            triggerAttributes={{
              text: 'Delete',
              variant: 'signal',
              icon: TrashIcon,
            }}
            variant="confirmation"
          />
        </Card>
      ))}
    </>
  );
}

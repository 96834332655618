import type {
  CreateTagDto,
  TagDto,
  UpdateTagDto,
} from '@portals/shared/admin/TagDto';

import { request } from '@/request';

export function createTag(data: CreateTagDto): Promise<TagDto> {
  return request('/tags', 'post', data);
}

export function updateTag(id: string, data: UpdateTagDto): Promise<TagDto> {
  return request(`/tags/${id}`, 'patch', data);
}

export function deleteTag(id: string): Promise<void> {
  return request(`/tags/${id}`, 'delete');
}

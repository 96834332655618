import { Button, Dialog, FormRow, Input, P } from '@dnb/eufemia';
import { trash as TrashIcon } from '@dnb/eufemia/icons';
import { useState } from 'react';

interface DeleteDialogProps {
  id: string;
  title: string;
  variant: 'tertiary' | 'secondary';
  onDelete: (id: string) => void;
}

export default function DeleteDialog({
  id,
  title,
  variant,
  onDelete,
}: DeleteDialogProps) {
  const [passphrase, setPassphrase] = useState('');

  return (
    <Dialog
      confirmType="warning"
      icon={TrashIcon}
      right="small"
      title="Are you sure you want to delete this content?"
      triggerAttributes={{
        text: 'Delete page',
        icon: TrashIcon,
        variant: variant,
      }}
      variant="confirmation"
    >
      <div>
        <P>
          This will also delete every children from this content, and this
          action cannot be undone.
        </P>

        <P>
          Please enter <b>{title}</b> in the field below
        </P>

        <FormRow direction="vertical" top="medium">
          <Input
            onPaste={(e) => e.preventDefault()}
            on_change={({ value }) => setPassphrase(value)}
            placeholder="Enter passphrase"
            stretch
          />
        </FormRow>
      </div>
      <Dialog.Action>
        <Button
          icon_position="left"
          on_click={({ close }) => {
            close();
          }}
          text="Cancel"
          variant="secondary"
        />
        <Button
          disabled={passphrase !== title}
          on_click={({ close }) => {
            onDelete(id);
            close();
          }}
          text="Delete"
        />
      </Dialog.Action>
    </Dialog>
  );
}

import { Button, Dd, Dl, Dt } from '@dnb/eufemia';
import { Link as RouterLink } from 'react-router-dom';

interface TeamSectionProps {
  teamId: string;
  name: string;
  organizationName: string;
}

export function TeamSection({
  teamId,
  name,
  organizationName,
}: TeamSectionProps): JSX.Element {
  return (
    <Dl top="large">
      <Dt>Team id</Dt>
      <Dd>{teamId}</Dd>
      <Dt>Team name</Dt>
      <Dd>{name}</Dd>
      <Dt>Organization</Dt>
      <Dd>{organizationName}</Dd>
      <Button
        element={RouterLink}
        icon="chevron-right"
        to={`/teams/${teamId}`}
        top="large"
        variant="secondary"
      >
        Show team
      </Button>
    </Dl>
  );
}

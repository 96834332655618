import { Link } from '@dnb/eufemia';
import type { ContentMetadataDto } from '@portals/shared/admin/ContentDto';
import { useMemo } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

import { type ContentRow, toRows } from './schemas';

export const COLUMNS: Column<ContentRow>[] = [
  {
    header: 'Name',
    attribute: 'title',
    render: ({ title, path }) => (
      <Link element={RouterLink} to={`/content${path}`}>
        {title}
      </Link>
    ),
  },

  {
    header: 'Parent',
    attribute: 'parent',
  },
  {
    header: 'Path',
    attribute: 'path',
  },
  {
    header: 'Updated at',
    attribute: 'updatedAt',
    render: ({ updatedAt }) => humanDate(updatedAt),
  },
  {
    header: 'Updated by',
    attribute: 'updatedBy',
    render: ({ updatedBy }) => (
      <Link element={RouterLink} to={`/users/${updatedBy}`}>
        {updatedBy ?? 'N/A'}
      </Link>
    ),
  },
];

export default function UnstructuredContentTable() {
  const navigate = useNavigate();
  const { data, isValidating: loadingAllContent } =
    useSWR<ContentMetadataDto[]>(`/content-all`);

  const rows = useMemo(() => toRows(data || []), [data]);

  if (loadingAllContent) {
    return <LoadingPage />;
  }

  return (
    <DataTable
      columns={COLUMNS}
      data={rows}
      defaultSortKey="path"
      filterBy={['title', 'path']}
      onEdit={(content) =>
        navigate(
          `/content/edit?parentId=${content.parentId}&contentId=${content.id}`,
        )
      }
    />
  );
}

import {
  Anchor,
  Button,
  Div,
  Flex,
  H2,
  P,
  Skeleton,
  Space,
} from '@dnb/eufemia';
import { edit as EditIcon } from '@dnb/eufemia/icons';
import type { ContentWithChildrenDto } from '@portals/shared/admin/ContentDto';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import DataTable from '@/components/DataTable';
import NoData from '@/components/NoData';
import { humanDate } from '@/utils';

import { COLUMNS, toRows } from '../../content/schemas';

import style from './index.module.css';

type Props = {
  path: string;
};
export default function TeamPagesSection({ path }: Props) {
  const navigate = useNavigate();

  const { data: contents, isValidating: loadingContent } =
    useSWR<ContentWithChildrenDto[]>(path);

  return (
    <Skeleton show={loadingContent}>
      {contents?.length === 0 ? (
        <Space className={style['NoContentContainer']}>
          <NoData message="This page has no content." />
        </Space>
      ) : (
        contents?.map((content) => (
          <Space
            bottom="medium"
            className={style['TableContainer']}
            key={content.id}
          >
            <Anchor element={Link} to={`/content${content.path}`}>
              <H2>{content.title}</H2>
            </Anchor>

            <P top="x-small">{humanDate(content.createdAt)}</P>

            <Flex.Horizontal top="small">
              <Button
                element={Link}
                icon="add"
                icon_position="left"
                target="_blank"
                to={`/content/edit?parentId=${content?.id}`}
                variant="tertiary"
              >
                Add content
              </Button>

              <Button
                element={Link}
                icon={EditIcon}
                icon_position="left"
                target="_blank"
                to={`/content/edit?parentId=${content.parentId}&contentId=${content.id}`}
                variant="tertiary"
              >
                Edit page
              </Button>
            </Flex.Horizontal>
            {content.children.length === 0 ? (
              <NoData message="No sub-pages available" />
            ) : (
              <DataTable
                barContent={<Div />}
                columns={COLUMNS}
                data={toRows(content.children)}
                defaultSortKey="sortIndex"
                onEdit={({ id, parentId }) => {
                  navigate(
                    `/content/edit?parentId=${parentId}&contentId=${id}`,
                  );
                }}
              />
            )}
          </Space>
        ))
      )}
    </Skeleton>
  );
}

import type { OrganizationInputDto } from '@portals/shared/admin/OrganizationDto';

import { request } from '@/request';

export function saveOrganization(data: OrganizationInputDto): Promise<void> {
  return request(`/organizations`, 'post', data);
}

export function deleteOrganization(id: string): Promise<void> {
  return request(`/organizations/${id}`, 'delete');
}

export function deleteUserFromOrganization(
  userId: string,
  organizationId: string,
): Promise<void> {
  return request(`/organizations/${organizationId}/users/${userId}`, 'delete');
}

export function addMissingUsersToOrganization(
  organizationId: string,
): Promise<void> {
  return request(`/organizations/${organizationId}/users`, 'patch');
}

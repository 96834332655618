import { Link, Span } from '@dnb/eufemia';
import {
  type ContentDto,
  type ContentMetadataDto,
  deleteContentSchema,
  PUBLISHED_STATUS,
} from '@portals/shared/admin/ContentDto';
import { Link as RouterLink } from 'react-router-dom';
import { boolean, string, z } from 'zod';

import type { Column } from '@/components/DataTable';
import Dot from '@/components/Dot';
import { humanDate } from '@/utils';

export const blobFormSchema = z.object({
  content: string().optional(),
});

export const basicContentFormSchema = z.object({
  id: string().uuid().optional(),
  title: string().min(1),
  slug: string().min(1),
  path: string().min(1),
  parent: string().min(1),
  isPublic: boolean(),
  publishedStatus: z.nativeEnum(PUBLISHED_STATUS),
  blob: blobFormSchema,
  templateId: string().uuid().optional(),
  teams: string().array(),
  organizations: string().array(),
  sortIndex: z
    .string()
    .refine((value) => !Number.isNaN(Number(value)), 'Please enter a number'),
  teamId: string().uuid().nullable().default(null),
  apiId: string().uuid().nullable().default(null),
  parentId: string().uuid().nullable().default(null),
});

export const advancedContentFormSchem = z.object({
  id: string().uuid().optional(),
  title: string().min(1),
  slug: string().min(1),
  path: string().min(1),
  parent: string().min(1),
  isPublic: boolean(),
  publishedStatus: z.nativeEnum(PUBLISHED_STATUS),
  blob: z.unknown(),
  templateId: string().uuid().optional(),
  teams: string().array(),
  organizations: string().array(),
  sortIndex: z
    .string()
    .refine((value) => !Number.isNaN(Number(value)), 'Please enter a number'),
  teamId: string().uuid().nullable().default(null),
  apiId: string().uuid().nullable().default(null),
});

export type AdvancedContentFormData = z.output<typeof advancedContentFormSchem>;
export type BasicContentFormData = z.output<typeof basicContentFormSchema>;
export type DeleteContent = z.output<typeof deleteContentSchema>;

export const toStatus = (value: number) => {
  switch (value) {
    case 0: {
      return 'Unpublished';
    }
    case 2: {
      return 'Published';
    }
    default: {
      return 'Draft';
    }
  }
};

export const toColor = (value: number) => {
  switch (value) {
    case 0: {
      return 'black-55';
    }
    case 2: {
      return 'summer-green';
    }
    default: {
      return 'sea-green';
    }
  }
};

export function toRows(content: (ContentMetadataDto | ContentDto)[]) {
  return content.map<ContentRow>(
    ({
      id,
      title,
      slug,
      publishedStatus,
      isPublic,
      updatedAt,
      path,
      sortIndex,
      parentId,
      parent,
      updatedBy,
    }) => ({
      id,
      title,
      publishedStatus,
      isPublic,
      slug,
      updatedAt: new Date(updatedAt),
      path,
      sortIndex,
      parentId,
      parent,
      updatedBy,
    }),
  );
}

export interface ContentRow {
  id: string;
  path: string;
  title: string;
  slug: string;
  exposure?: string;
  publishedStatus: number;
  isPublic: boolean;
  updatedAt: Date;
  sortIndex: number;
  parentId: string | null;
  parent: string | null;
  updatedBy: string | null;
}

export const COLUMNS: Column<ContentRow>[] = [
  {
    header: 'Name',
    attribute: 'title',
    render: ({ title, path }) => (
      <Link element={RouterLink} to={`/content${path}`}>
        {title}
      </Link>
    ),
  },
  {
    header: 'Slug',
    attribute: 'slug',
  },

  {
    header: 'Stage',
    attribute: 'publishedStatus',
    render: ({ publishedStatus }) => (
      <span>
        <Dot color={toColor(publishedStatus)} />
        <Span left="x-small">{toStatus(publishedStatus)}</Span>
      </span>
    ),
  },

  {
    header: 'Updated at',
    attribute: 'updatedAt',
    render: ({ updatedAt }) => humanDate(updatedAt),
  },
  {
    header: 'Sort index',
    attribute: 'sortIndex',
  },
];

export enum TabKey {
  content = 'content',
  info = 'info',
}

export const TABS = [
  { title: 'Info & visibility', key: TabKey.info },
  { title: 'Content', key: TabKey.content },
];

export const DEFAULT_FORM: BasicContentFormData = {
  title: '',
  slug: '',
  path: '',
  parent: '/',
  isPublic: false,
  publishedStatus: PUBLISHED_STATUS.UNPUBLISHED,
  teams: [],
  organizations: [],
  blob: {
    content: '',
  },
  sortIndex: '1',
  teamId: null,
  apiId: null,
  parentId: null,
};

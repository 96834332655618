import {
  Button,
  Dropdown,
  FormRow,
  FormSet,
  H1,
  Input,
  Section,
  Space,
} from '@dnb/eufemia';
import { trash as TrashIcon } from '@dnb/eufemia/icons';
import { useEufemiaForm, useFieldArray } from '@portals/shared-frontend/hooks';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import slugify from 'slugify';
import { z } from 'zod';

import { saveTemplate } from '@/api/templates';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';

import style from './TemplateForm.module.css';

export enum SchemaType {
  string = 'string',
  markdown = 'markdown',
  boolean = 'boolean',
  text = 'text',
}

export const schemaForm = z.object({
  name: z.string().min(1),
  schema: z
    .object({
      name: z.string().min(1),
      type: z.string().and(z.enum(['string', 'markdown', 'boolean', 'text'])),
    })
    .array(),
});
export type SchemaForm = z.infer<typeof schemaForm>;

const DEFAULT_FORM: SchemaForm = {
  name: '',
  schema: [
    {
      name: '',
      type: SchemaType.string,
    },
  ],
};

export default function ContentFormByParent(): JSX.Element {
  const navigate = useNavigate();
  const {
    controller,
    controller: { setValue },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(schemaForm, {
    ...DEFAULT_FORM,
  });

  const schemaArray = useFieldArray(controller, 'schema');

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        await saveTemplate(data);
        navigate('..');
      },
      [navigate],
    ),
  );

  return (
    <Container centered size="small">
      {submitting && <LoadingModal />}

      <H1 bottom={false} top="large">
        New template
      </H1>
      <FormSet label_direction="vertical" on_submit={onSubmit}>
        <Section
          className={style['FormSet']}
          spacing="x-large"
          style_type="white"
        >
          <FormRow bottom="medium">
            <Input
              label="Schema title"
              size={40}
              stretch
              {...register.input('name')}
              on_blur={({ value }) =>
                setValue('name', slugify(value, { lower: true }))
              }
            />
          </FormRow>

          <Space>
            {schemaArray.items.map(({ index, remove }) => (
              <Space
                bottom="small"
                className={style['FormSet']}
                key={`item-${index}`}
              >
                <FormRow>
                  <Input
                    label="Field name"
                    stretch
                    {...register.input(`schema.${index}.name`)}
                    on_blur={({ value }) =>
                      setValue(
                        `schema.${index}.name`,
                        slugify(value, { lower: true }),
                      )
                    }
                  />
                </FormRow>
                <FormRow>
                  <Dropdown
                    data={SchemaType}
                    label="Field type"
                    {...register.dropdown(`schema.${index}.type`)}
                    top="small"
                  />
                </FormRow>
                <FormRow top="small">
                  <Button
                    icon={TrashIcon}
                    icon_position="left"
                    on_click={remove}
                    variant="tertiary"
                  >
                    Remove
                  </Button>
                </FormRow>
              </Space>
            ))}
            <Button
              icon="add"
              icon_position="left"
              on_click={() =>
                schemaArray.push({
                  name: '',
                  type: SchemaType.string,
                })
              }
              top="medium"
              variant="tertiary"
            >
              Add field
            </Button>
          </Space>
        </Section>
        <Section spacing="small" style_type="white">
          <Button
            disabled={submitting}
            icon="save"
            icon_position="left"
            type="submit"
          >
            Save changes
          </Button>
        </Section>
      </FormSet>
    </Container>
  );
}

import { Accordion, Link, P, Table, Td, Tr } from '@dnb/eufemia';

import style from './index.module.css';

export default function MarkdownHelperTable() {
  return (
    <Accordion
      className={style['Container']}
      title="Markdown Cheat Sheet"
      top="medium"
      variant="plain"
    >
      <Table>
        <thead>
          <Tr>
            <Td>Element</Td>
            <Td>Markdown Syntax</Td>
          </Tr>
        </thead>
        <tbody>
          <Tr>
            <Td>Heading</Td>
            <Td>
              # H1 <br /> ## H2 <br /> ### H3
            </Td>
          </Tr>
          <Tr>
            <Td>Bold</Td>
            <Td>**bold text**</Td>
          </Tr>
          <Tr>
            <Td>Italic</Td>
            <Td>*italicized text*</Td>
          </Tr>
          <Tr>
            <Td>Blockquote</Td>
            <Td>{`> `}blockquote</Td>
          </Tr>
          <Tr>
            <Td>Ordered List</Td>
            <Td>
              <P>1. First item</P>
              <P>2. Second item</P>
              <P>3. Third item</P>
            </Td>
          </Tr>
          <Tr>
            <Td>Unoreded List</Td>
            <Td>
              <P> - First item</P>
              <P> - Second item</P>
              <P> - Third item</P>
            </Td>
          </Tr>
          <Tr>
            <Td>New Line</Td>
            <Td>
              <P>\</P>
            </Td>
          </Tr>
          <Tr>
            <Td>Code</Td>
            <Td>`code`</Td>
          </Tr>
          <Tr>
            <Td>Horizontal Rule</Td>
            <Td> --- </Td>
          </Tr>
          <Tr>
            <Td>Link</Td>
            <Td> [title](https://www.example.com) </Td>
          </Tr>
          <Tr>
            <Td>Image</Td>
            <Td>![alt text](image.jpg)</Td>
          </Tr>
          <Tr>
            <Td>Table</Td>
            <Td>
              | Syntax | Description | <br />
              | ----------- | ----------- | <br />
              | Header | Title | <br />
              | Paragraph | Text | <br />
            </Td>
          </Tr>
          <Tr>
            <Td>
              <P>Need more info about Markdown?</P>
              <Link
                href="https://www.markdownguide.org/cheat-sheet/"
                target="_blank"
              >
                https://www.markdownguide.org/cheat-sheet/
              </Link>
            </Td>
          </Tr>
        </tbody>
      </Table>
    </Accordion>
  );
}

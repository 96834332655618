// eslint-disable-next-line simple-import-sort/imports
import {
  Blockquote,
  Code,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Hr,
  Li,
  Link,
  Ol,
  P,
  Table,
  Ul,
} from '@dnb/eufemia';
import mermaid from 'mermaid';
import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-yaml';
import type { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './index.styles.css';
import slugify from 'slugify';

interface MarkdownProps {
  children: string;
}
interface Props {
  children: ReactNode | string;
  inline?: boolean;
  className?: string;
}

mermaid.mermaidAPI.initialize({
  theme: 'neutral',
  startOnLoad: false,
  sequence: {
    // @ts-ignore
    fontFamily: 'Dnb, DnbMono',
  },
  // @ts-ignore
  themeVariables: {
    fontFamily: 'Dnb, DnbMono',
    primaryColor: '#007272',
    secondaryColor: '#008484',
    tertiaryColor: '#A5E1D2',
    primaryBorderColor: '#00343E',
    secondaryBorderColor: '#757575',
    tertiaryBorderColor: '#333333',
    noteBorderColor: '#CCCCCC',
    secondaryTextColor: '#00343E',
    tertiaryTextColor: '#00343E',
    lineColor: '#00343E',
    textColor: '#333333',
    nodeBkg: '#A5E1D2',
    mainBkg: '#D2F0E9',
    nodeBorder: '#A5E1D2',
    clusterBkg: '#EBFFFA',
    clusterBorder: '#14555A',
    defaultLinkColor: '#00343E',
    titleColor: '#333333',
    edgeLabelBackground: '#A5E1D2',
    nodeTextColor: '#00343',
    actorBorder: '#A5E1D2',
    actorBkg: '#D2F0E9',
    actorTextColor: '#000000',
    actorLineColor: '#737373',
    labelBoxBkgColor: '#14555A',
    signalColor: '#333333',
    signalTextColor: '#333333',
    labelBoxBorderColor: '#14555A',
    labelTextColor: '#000000',
    loopTextColor: '#000000',
    activationBorderColor: '#757575',
    activationBkgColor: '#F8F8F8',
    sequenceNumberColor: '#FFFFFF',
    sectionBkgColor: '#28B482',
    altSectionBkgColor: '#FFFFFF',
    sectionBkgColor2: '#28B482',
    taskBorderColor: '#14555A',
    taskBkgColor: '#008484',
    activeTaskBorderColor: '#14555A',
    activeTaskBkgColor: '#A5E1D2',
    gridColor: '#CCCCCC',
    doneTaskBkgColor: '#CCCCCC',
    doneTaskBorderColor: '#737373',
    critBorderColor: '#FF5400',
    critBkgColor: '#FDEEEE',
    todayLineColor: '#FF5400',
    taskTextLightColor: '#FFFFFF',
    taskTextColor: '#FFFFFF',
    taskTextDarkColor: '#000000',
    taskTextOutsideColor: '#000000',
    taskTextClickableColor: '#23195A',
    labelColor: '#000000',
    altBackground: '#FAFAFA',
    errorBkgColor: '#FDEEEE',
    errorTextColor: '#FF5400',
    fillType0: '#007272',
    fillType1: '#008484',
    fillType2: '#A5E1D2',
    fillType3: '#14555A',
    fillType4: '#B3D5D5',
    fillType5: '#D2F0E9',
    fillType6: '#E9F8F4',
    fillType7: '#EBFFFA',
  },
});

export default function Markdown({ children }: MarkdownProps): JSX.Element {
  const CodeSection = ({ children, ...props }: Props) => {
    if (
      Object.keys(props).includes('className') &&
      props['className'] === 'language-mermaid'
    ) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: mermaid.mermaidAPI.render(
              `mermaid-diagram-${Math.floor(Math.random() * 2000)}`,
              (children && children.toString()) || '',
            ),
          }}
        />
      );
    }
    if (!props?.inline) {
      const lang = props['className']
        ? (props['className'] as string).replace('language-', '')
        : 'txt';
      let prismCode;

      try {
        prismCode = Prism.highlight(
          children?.toString() || '',
          Prism.languages[lang],
          lang,
        );
      } catch {
        prismCode = Prism.highlight(
          children?.toString() || '',
          Prism.languages['txt'],
          'txt',
        );
      }
      return (
        <div className="code">
          <pre className={`dnb-pre prism-code language-${lang}`}>
            <div dangerouslySetInnerHTML={{ __html: prismCode }} />
          </pre>
        </div>
      );
    }

    return <Code>{children}</Code>;
  };

  return (
    <ReactMarkdown
      components={{
        br: () => <br />,
        h1: ({ ...props }) => (
          <H1
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H1>
        ),
        h2: ({ ...props }) => (
          <H2
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H2>
        ),
        h3: ({ ...props }) => (
          <H3
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H3>
        ),
        h4: ({ ...props }) => (
          <H4
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H4>
        ),
        h5: ({ ...props }) => (
          <H5
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H5>
        ),
        h6: ({ ...props }) => (
          <H6
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H6>
        ),
        hr: () => <Hr />,
        ul: ({ ...props }) => (
          <Ul bottom="medium" top="medium">
            {props.children}
          </Ul>
        ),
        ol: ({ ...props }) => (
          <Ol bottom="medium" top="medium">
            {props.children}
          </Ol>
        ),
        li: ({ ...props }) => (
          <Li bottom="medium" top="medium">
            {props.children}
          </Li>
        ),
        blockquote: ({ ...props }) => (
          <Blockquote bottom="medium" top="medium">
            {props.children}
          </Blockquote>
        ),
        table: ({ ...props }) => (
          <Table bottom="medium" top="medium" {...props}>
            {props.children}
          </Table>
        ),
        a: ({ ...props }) => <Link {...props}>{props.children}</Link>,
        code: ({ ...props }) => (
          <CodeSection {...props}>{props.children}</CodeSection>
        ),
        p: ({ ...props }) => <P {...props}>{props.children}</P>,
      }}
      remarkPlugins={[remarkGfm]}
    >
      {children}
    </ReactMarkdown>
  );
}

import { Dialog } from '@dnb/eufemia';
import { saveAs } from 'file-saver';
import { utils, type WorkBook, write } from 'xlsx';

interface EmailObject {
  email: string;
}

interface DownloadProps {
  data: EmailObject[];
  title: string;
}

export function Download({ data, title }: DownloadProps): JSX.Element {
  const onConfirmDownload = () => {
    // Create workbook and worksheet
    const wb: WorkBook = utils.book_new();
    const ws = utils.json_to_sheet(data);

    // Add worksheet to workbook
    utils.book_append_sheet(wb, ws, 'Sheet1');

    // Create Excel file and trigger download
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(blob, `${title}SubscriberEmails.xlsx`);
  };

  return (
    <Dialog
      confirmText="Download"
      description="Are you sure you want to download emails? Please make sure
                to delete this file from your PC since it contains customer data!"
      icon="download_medium"
      onConfirm={({ close }) => {
        close();
        onConfirmDownload();
      }}
      title="Download e-mails"
      triggerAttributes={{
        text: 'Download e-mails',
        icon: 'download',
      }}
      variant="confirmation"
    />
  );
}

import { Space } from '@dnb/eufemia';
import styled from '@emotion/styled';

type SpaceProps = {
  top: string;
};

export const RowWrapper = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;

  .col {
    margin-right: var(--spacing-small);

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const Psd2CertificateCard = styled.div`
  border: 1px solid var(--color-black-8);
  border-radius: 5px;
  padding: 1.5rem;
  text-align: center;
  max-width: 15rem;
  position: relative;

  &-deletedBox {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
  }
`;

export const Psd2CertificateList = styled(Space)<SpaceProps>`
  display: grid;
  grid-template-columns: repeat(auto-fill, 15rem);
  gap: 1rem;
`;

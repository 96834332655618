/* eslint-disable max-lines */
import { Accordion, Card, Drawer, H3, P, ToggleButton } from '@dnb/eufemia';
import type { ApiWithOwnerDto } from '@portals/shared/admin/ApiDto';
import { Type } from '@portals/shared/admin/DashboardDto';
import type { ScopeFromAllSourcesDto } from '@portals/shared/admin/ScopeDto';
import { enUS } from 'date-fns/locale';
import { useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import useSWR from 'swr';

import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';

import ScopeDetailView from './ScopeDetailView';

interface ScopeRow {
  scopeName: string;
  isInCiam: string;
  isInCiamTest: string;
  isInPortal: string;
}

const COLUMNS: Column<ScopeRow>[] = [
  { header: 'Scope', attribute: 'scopeName' },
  { header: 'Is in CIAM?', attribute: 'isInCiam' },
  { header: 'Is in CIAM test?', attribute: 'isInCiamTest' },
  { header: 'Is in Portals?', attribute: 'isInPortal' },
];

export interface ScopesTableProps {
  scopes: ScopeFromAllSourcesDto[];
  loading?: boolean;
}

export default function ScopesTable({
  scopes,
  loading = false,
}: ScopesTableProps): JSX.Element {
  const { data: apis, isValidating: apisLoading } =
    useSWR<ApiWithOwnerDto[]>('/api');

  const [selectedScope, selectScope] = useState<ScopeFromAllSourcesDto | null>(
    null,
  );
  const [quickFilter, setQuickFilter] = useState<
    | 'unsynced'
    | 'synced'
    | 'isInCiam'
    | 'isInCiamTest'
    | 'isInPortal'
    | 'isInPortalWithSn'
    | 'all'
  >('all');

  const apiIdsWithSn = useMemo(() => {
    if (apis) {
      return apis.filter((a) => a.enableServiceNowRequest).map((api) => api.id);
    }
    return [];
  }, [apis]);

  const filteredScope = useMemo(
    () =>
      scopes
        .filter((scope) => {
          switch (quickFilter) {
            case 'isInCiam': {
              return Boolean(scope.ciamScope);
            }
            case 'isInCiamTest': {
              return Boolean(scope.ciamTestScope);
            }
            case 'isInPortal': {
              return Boolean(scope.portalScope);
            }
            case 'synced': {
              return Boolean(
                scope.ciamScope && scope.ciamTestScope && scope.portalScope,
              );
            }
            case 'unsynced': {
              return !(
                scope.ciamScope &&
                scope.ciamTestScope &&
                scope.portalScope
              );
            }
            case 'isInPortalWithSn': {
              return Boolean(
                apiIdsWithSn.includes(scope.portalScope?.apiId || ''),
              );
            }
            default: {
              return true;
            }
          }
        })
        .map((scope) => ({
          ...scope,
          isInCiam: scope.ciamScope ? 'Yes' : 'No',
          isInCiamTest: scope.ciamTestScope ? 'Yes' : 'No',
          isInPortal: scope.portalScope ? 'Yes' : 'No',
        })),
    [apiIdsWithSn, quickFilter, scopes],
  );

  const quickFilterView = useMemo(
    () => (
      <ToggleButton.Group
        bottom="medium"
        label="Quick filters:"
        on_change={({ value }) => {
          setQuickFilter(value);
        }}
      >
        <ToggleButton
          text={`Unsynced Scopes: ${
            scopes.filter(
              (scope) =>
                !(scope.ciamScope && scope.ciamTestScope && scope.portalScope),
            ).length
          }`}
          value="unsynced"
        />
        <ToggleButton
          text={`Synced Scopes: ${
            scopes.filter((scope) =>
              Boolean(
                scope.ciamScope && scope.ciamTestScope && scope.portalScope,
              ),
            ).length
          }`}
          value="synced"
        />
        <ToggleButton
          text={` Scopes in CIAM: ${
            scopes.filter((scope) => scope.ciamScope).length
          }`}
          value="isInCiam"
        />
        <ToggleButton
          text={` Scopes in CIAM Test: ${
            scopes.filter((scope) => scope.ciamTestScope).length
          }`}
          value="isInCiamTest"
        />
        <ToggleButton
          text={`Scopes in portal: ${
            scopes.filter((scope) => scope.portalScope).length
          }`}
          value="isInPortal"
        />
        <ToggleButton
          text={`Scopes in portal with SN integration: ${
            scopes.filter((scope) =>
              apiIdsWithSn.includes(scope.portalScope?.apiId || ''),
            ).length
          }`}
          value="isInPortalWithSn"
        />
        <ToggleButton text={`Show all: ${scopes.length}`} value="all" />
      </ToggleButton.Group>
    ),
    [apiIdsWithSn, scopes],
  );

  const chartData = useMemo(() => {
    let portalScopeCount = 0;
    let ciamScopeCount =
      scopes.filter((s) => s.ciamScope).length -
      scopes.filter((s) => s.ciamScope?.metadata['createdBy']).length;
    let portalScopeWithSnCount = 0;
    const data = scopes
      .filter((scope) => typeof scope.portalScope?.createdAt === 'string')
      .sort((a, b) => {
        const dateA = new Date(a.portalScope?.createdAt as string);
        const dateB = new Date(b.portalScope?.createdAt as string);
        return dateA.getTime() - dateB.getTime();
      })
      .map((scope) => {
        portalScopeCount = portalScopeCount + 1;
        if (scope.ciamScope?.metadata['createdBy']) {
          ciamScopeCount = ciamScopeCount + 1;
        }
        if (apiIdsWithSn.includes(scope.portalScope?.apiId || '')) {
          portalScopeWithSnCount = portalScopeWithSnCount + 1;
        }
        return {
          date: scope.portalScope?.createdAt,
          portalScopeCount,
          ciamScopeCount,
          portalScopeWithSnCount,
        };
      });
    return {
      type: Type.line,
      labels: data.map((d) => d.date || ''),
      datasets: [
        {
          label: 'Scopes',
          data: data.map((d) => d.ciamScopeCount),
          borderColor: '#A5E1D2',
          backgroundColor: '#A5E1D280',
          fill: false,
        },
        {
          label: 'Scopes in portal',
          data: data.map((d) => d.portalScopeCount),
          borderColor: '#28B482',
          backgroundColor: '#28B48280',
          fill: true,
        },
        {
          label: 'Scopes with SN*',
          data: data.map((d) => d.portalScopeWithSnCount),

          borderColor: '#007272',
          backgroundColor: '#00727280',
          fill: true,
        },
      ],
      title: 'Scopes',
    };
  }, [apiIdsWithSn, scopes]);

  if (loading || apisLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Drawer
        omitTriggerButton
        onClose={() => selectScope(null)}
        openState={selectedScope != null}
        title="Scope Details"
      >
        {apis && selectedScope && (
          <ScopeDetailView apis={apis} scope={selectedScope} />
        )}
      </Drawer>
      <Accordion
        bottom="small"
        expanded
        id="single-accordion"
        remember_state
        title="Scope graph"
      >
        <Card>
          <H3>Scopes</H3>
          <Line
            data={{ datasets: chartData.datasets, labels: chartData.labels }}
            options={{
              responsive: true,
              scales: {
                x: {
                  type: 'time' as const,
                  adapters: {
                    date: {
                      locale: enUS,
                    },
                  },
                },
              },
            }}
          />
        </Card>
        <P top="small">
          *Dates for <i>Scopes with SN</i> are assumed to be same as scope
          creation date.
        </P>
      </Accordion>

      {quickFilterView}
      <DataTable
        barContent={<span />} // Just a work around to keep search bar to the right side
        columns={COLUMNS}
        data={filteredScope}
        defaultSortKey="scopeName"
        filterBy={['scopeName']}
        onShow={(scope) => selectScope(scope)}
      />
    </>
  );
}

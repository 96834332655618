import { Anchor, Button, H1 } from '@dnb/eufemia';
import type { ApiDto } from '@portals/shared/admin/ApiDto';
import type { ApiIncidentDto } from '@portals/shared/admin/ApiIncidentDto';
import { keyByAndMap } from '@portals/shared-frontend/utils';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

interface IncidentRow {
  id: string;
  type: string;
  createdAt: Date;
  description: string | null;
  api?: string;
}

const COLUMNS: Column<IncidentRow>[] = [
  { header: 'Type', attribute: 'type' },
  {
    header: 'ID',
    attribute: 'id',
    render: ({ id }) => (
      <Anchor element={Link} to={`/api-incidents/${id}`}>
        {id}
      </Anchor>
    ),
  },
  { header: 'API', attribute: 'api' },
  { header: 'Description', attribute: 'description' },
  {
    header: 'Created',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDate(createdAt),
  },
];

const INCIDENT_TYPES = {
  incident: 'Incident',
  maintenance: 'Maintenance',
};

export default function IncidentList(): JSX.Element {
  const navigate = useNavigate();

  const { data: incidents, isValidating: incidentsLoading } =
    useSWR<ApiIncidentDto[]>('/api-incidents');
  const { data: apis, isValidating: apisLoading } = useSWR<ApiDto[]>('/api');

  const isLoading = incidentsLoading || apisLoading;

  const rows = useMemo(() => {
    if (!incidents || !apis) {
      return [];
    }

    const apiNamesById = keyByAndMap(apis, 'id', 'name');
    return incidents.map<IncidentRow>(
      ({ id, type, createdAt, apiId, description }) => ({
        id,
        type: INCIDENT_TYPES[type],
        createdAt: new Date(createdAt),
        description,
        api: apiNamesById[apiId],
      }),
    );
  }, [incidents, apis]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>

      <H1 top="large">Status</H1>
      <DataTable
        barContent={
          <Button
            element={Link}
            icon="add"
            icon_position="left"
            to="/api-incidents/new"
            variant="secondary"
          >
            Add incident or maintenance
          </Button>
        }
        columns={COLUMNS}
        data={rows}
        defaultSortKey="createdAt"
        filterBy={['id', 'api', 'description']}
        onEdit={({ id }) => navigate(id)}
        reverseSort
      />
    </>
  );
}

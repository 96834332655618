import {
  Button,
  Dd,
  Dialog,
  Dl,
  Dt,
  FormRow,
  FormSet,
  H1,
  H2,
  H3,
  P,
  Section,
  Space,
  Textarea,
  ToggleButton,
} from '@dnb/eufemia';
import type {
  ApiGrantWithApiAndHistoryDto,
  AppWithApiGrantsAndOwnerDto,
} from '@portals/shared/admin/ApiGrantDto';
import type { OrganizationDto } from '@portals/shared/admin/OrganizationDto';
import { useSafeState } from '@portals/shared-frontend/hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import LoadingPage from '@/components/LoadingPage';
import { humanDatetime } from '@/utils';

export default function GrantShow(): JSX.Element {
  const { appId } = useParams();
  const { data: app, isValidating: appLoading } =
    useSWR<AppWithApiGrantsAndOwnerDto>(`/api-grants/${appId}`);

  const organizationId = app?.user?.organizationId || app?.team?.organizationId;

  const { data: organization, isValidating: organizationLoading } =
    useSWR<OrganizationDto>(
      organizationId ? `/organizations/${organizationId}` : null,
    );

  const isLoading = appLoading || organizationLoading;

  const sections = useMemo(
    () => [
      {
        title: 'Pending',
        grants: app?.grants.filter(({ status }) => status === 'pending') ?? [],
      },
      {
        title: 'Approved',
        grants: app?.grants.filter(({ status }) => status === 'approved') ?? [],
      },
      {
        title: 'Rejected',
        grants: app?.grants.filter(({ status }) => status === 'rejected') ?? [],
      },
      {
        title: 'Contacted',
        grants:
          app?.grants.filter(({ status }) => status === 'contacted') ?? [],
      },
    ],
    [app],
  );

  if (isLoading || !app) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/api-grants">API Grants</BackButton>

      <Container centered size="small">
        <H1 top="large">{app.name}</H1>
        <Section spacing="x-large">
          <H2>App information</H2>
          <Dl top="large">
            <Dt>Name</Dt>
            <Dd>{app.name}</Dd>
            <Dt>Description</Dt>
            <Dd>{app.description || <i>No description</i>}</Dd>
          </Dl>
        </Section>
        <Section spacing="x-large" style_type="white">
          <H2>Owner</H2>
          {app.user && (
            <Dl top="large">
              <Dt>First name</Dt>
              <Dd>{app.user.firstName}</Dd>
              <Dt>Last name</Dt>
              <Dd>{app.user.lastName}</Dd>
              <Dt>Email</Dt>
              <Dd>{app.user.email}</Dd>
              <Dt>Organization</Dt>
              <Dd>{organization?.name || <i>No organization</i>}</Dd>
            </Dl>
          )}
          {app.team && (
            <Dl top="large">
              <Dt>Team name</Dt>
              <Dd>{app.team.name}</Dd>
              {app.team.description && (
                <>
                  <Dt>Description</Dt>
                  <Dd>{app.team.description}</Dd>
                </>
              )}
              <Dt>Organization</Dt>
              <Dd>{organization?.name || <i>No organization</i>}</Dd>
            </Dl>
          )}
        </Section>
        <Section spacing="x-large">
          <H2>Grants</H2>

          {sections.map(({ title, grants }) => (
            <Space key={title} top="large">
              <H3 top="large">{title}</H3>
              {grants.length > 0 ? (
                grants.map((grant) => (
                  <GrantForm grant={grant} key={grant.id} />
                ))
              ) : (
                <P>
                  <i>No grants</i>
                </P>
              )}
            </Space>
          ))}
        </Section>
      </Container>
    </>
  );
}

interface GrantFormProps {
  grant: ApiGrantWithApiAndHistoryDto;
}

function GrantForm({ grant }: GrantFormProps): JSX.Element {
  const [open, setOpen] = useSafeState(false);
  return (
    <Dialog
      noAnimation
      onOpen={() => setOpen(true)}
      openState={open}
      title={grant.api.name}
      triggerAttributes={{
        icon: 'chevron_right',
        icon_position: 'right',
        title: grant.api.name,
        variant: 'secondary',
      }}
    >
      <FormSet prevent_submit vertical>
        <FormRow label="Requested">
          <P>{humanDatetime(grant.createdAt)}</P>
        </FormRow>
        <FormRow label="Last updated" top="small">
          <P>{humanDatetime(grant.updatedAt)}</P>
        </FormRow>
        <FormRow label="Current use case" top="small">
          <P>{grant.useCase}</P>
        </FormRow>
        <FormRow top="medium">
          <ToggleButton.Group label="Decision">
            <ToggleButton value="approved" variant="radio">
              Approve
            </ToggleButton>
            <ToggleButton value="rejected" variant="radio">
              Reject
            </ToggleButton>
            <ToggleButton value="contacted" variant="radio">
              Contact
            </ToggleButton>
          </ToggleButton.Group>
        </FormRow>
        <FormRow top="medium">
          <Textarea
            cols="40"
            label="Comment (for internal use only)"
            rows="10"
          />
        </FormRow>
        <FormRow top="medium">
          <P>
            THIS FEATURE IS NO LONGER IN USE. IT IS ONLY MAINTAINED FOR THE
            PURPOSE OF VIEWING
          </P>
          <Button disabled icon="save" icon_position="left" type="submit">
            Save
          </Button>
        </FormRow>
      </FormSet>
    </Dialog>
  );
}

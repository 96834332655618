import { Anchor, Button, H1 } from '@dnb/eufemia';
import type { ProjectDto } from '@portals/shared/admin/ProjectDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { request } from '@/request';

const COLUMNS: Column<ProjectDto>[] = [
  {
    header: 'Name',
    attribute: 'name',
    render: ({ name, id }) => {
      return (
        <Anchor element={Link} to={`/projects/${id}`}>
          {name}
        </Anchor>
      );
    },
  },
  { header: 'Description', attribute: 'description' },
  {
    header: 'Ciam UAT app id',
    attribute: 'ciamUatAppId',
  },
  {
    header: 'Ciam Prod app id',
    attribute: 'ciamProdAppId',
  },
];

export default function ProjectList(): JSX.Element {
  const navigate = useNavigate();

  const {
    data: projects,
    isValidating,
    mutate,
  } = useSWR<ProjectDto[]>(`/projects`);

  const isLoading = isValidating;

  const onSync = useAsync(async () => {
    await request(`/projects/sync`, 'post');
    mutate();
  }, [mutate]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>

      <H1 top="large">Projects</H1>

      {projects && (
        <DataTable
          barContent={
            <div>
              <Button
                element={Link}
                icon="add"
                icon_position="left"
                right="small"
                to="/projects/new"
                variant="secondary"
              >
                Add Project
              </Button>
              <Button
                icon="save"
                icon_position="left"
                onClick={onSync.execute}
                right="small"
                top="small"
                variant="secondary"
              >
                Sync Project
              </Button>
            </div>
          }
          columns={COLUMNS}
          data={projects}
          defaultSortKey="name"
          filterBy={['name', 'description', 'ciamUatAppId', 'ciamProdAppId']}
          onEdit={({ id }) => navigate(id)}
        />
      )}
    </>
  );
}

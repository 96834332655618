import { FormRow, HelpButton, Input } from '@dnb/eufemia';
import {
  type Controller,
  useEufemiaProps,
} from '@portals/shared-frontend/hooks';

import type { basicContentFormSchema } from '../../schemas';

type Props = {
  controller: Controller<typeof basicContentFormSchema>;
};
export function SharedForm({ controller }: Props) {
  const register = useEufemiaProps(controller);
  return (
    <>
      <FormRow top="medium">
        <Input label="Title" size={40} {...register.input('title')} />
      </FormRow>
      <FormRow top="medium">
        <Input
          label="Slug"
          size={40}
          {...register.input('slug')}
          suffix={
            <HelpButton title="What is a slug?">
              The slug is a human readable ID of this particular document. It
              will be automatically be filled based on the title. If the
              conflicting slugs appear, you will have to make a little change
              yourself, but make sure it still makes sense to the page you
              adding.
            </HelpButton>
          }
        />
      </FormRow>
      <FormRow top="medium">
        <Input
          label="Path"
          size={40}
          {...register.input('path')}
          suffix={
            <HelpButton title="What is the path used for?">
              The path is defining the path where this particular document will
              be rendered. It is using the slug and the path from the document
              defined as the parent.
            </HelpButton>
          }
        />
      </FormRow>
      <FormRow top="medium">
        <Input
          label="Parent path"
          size={40}
          {...register.input('parent')}
          disabled
          suffix={
            <HelpButton title="What is parent?">
              The parent field is here just to illustrate that this document
              will be stored under the specific parent.
            </HelpButton>
          }
        />
      </FormRow>
      <FormRow top="medium">
        <Input
          label="Sort index"
          type="Number"
          {...register.input('sortIndex')}
        />
      </FormRow>
    </>
  );
}

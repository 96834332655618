import {
  Button,
  Dd,
  Dialog,
  Dl,
  Dt,
  FormStatus,
  H1,
  H2,
  InfoCard,
  P,
  Section,
  Space,
} from '@dnb/eufemia';
import { trash_medium as TrashIcon } from '@dnb/eufemia/icons';
import type { Psd2CertificateDto } from '@portals/shared/admin/Psd2CertificateDto';
import type { UserDetailsDto } from '@portals/shared/admin/UserDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { deleteUser } from '@/api/user';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import { humanDate, humanDatetime } from '@/utils';

import * as styles from './User.styles';

export default function UserShow(): JSX.Element {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const { data, isValidating: detailsLoading } = useSWR<UserDetailsDto>(
    `/users/${userId}`,
  );

  const onDelete = useAsync(async () => {
    try {
      if (userId) {
        await deleteUser(userId);
        mutate('/users');
        navigate('/users');
      }
    } catch (error) {
      if (ApiError.isApiError(error)) {
        return setErrorMessage(error.message);
      }
      throw error;
    }
  }, [navigate, userId]);

  if (detailsLoading || !data) {
    return <LoadingPage />;
  }
  const { user, organization, teams, apps, certificates } = data;

  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <>
      {onDelete.waiting && <LoadingModal />}
      <BackButton to="/users">Users</BackButton>
      <Container centered size="small">
        <H1 bottom="x-large">{fullName}</H1>
        <Section spacing="x-large">
          <H2>User info</H2>
          <Dl top="large">
            <Dt>First name</Dt>
            <Dd>{user.firstName}</Dd>
            <Dt>Last name</Dt>
            <Dd>{user.lastName}</Dd>
            {organization && (
              <>
                <Dt>Organization</Dt>
                <Dd>{organization?.name}</Dd>
              </>
            )}
            <Dt>Member since</Dt>
            <Dd>{humanDatetime(user.createdAt)}</Dd>
          </Dl>
        </Section>
        <Section spacing="x-large" style_type="white">
          <H2>Apps</H2>
          <Space top="medium">
            {apps.length > 0 ? (
              apps.map((app) => (
                <Button
                  element={Link}
                  icon="chevron_right"
                  key={app.id}
                  right="small"
                  to={`/apps/${app.id}`}
                  top="small"
                  variant="secondary"
                >
                  {app.name}
                </Button>
              ))
            ) : (
              <P top="large">No apps.</P>
            )}
          </Space>
        </Section>
        <Section spacing="x-large">
          <H2>Teams</H2>
          <Space top="medium">
            {teams.length > 0 ? (
              teams.map((team) => (
                <Button
                  element={Link}
                  icon="chevron_right"
                  key={team.id}
                  right="small"
                  to={`/teams/${team.id}`}
                  top="small"
                  variant="secondary"
                >
                  {team.name}
                </Button>
              ))
            ) : (
              <P top="large">No teams.</P>
            )}
          </Space>
        </Section>
        <Section spacing="x-large" style_type="white">
          <H2>PSD2 sandbox certificates</H2>
          <styles.Psd2CertificateList top="large">
            {certificates.length > 0 ? (
              certificates.map((cert) => (
                <Psd2CertificateCard certificate={cert} key={cert.id} />
              ))
            ) : (
              <P>No certificates.</P>
            )}
          </styles.Psd2CertificateList>
        </Section>
        <Section spacing="x-large" variant="warning">
          <H2>Danger zone</H2>

          <P top="large">
            This will permanently delete the user, including all data, like
            apps, accesses and team relations.
          </P>
          {errorMessage && (
            <FormStatus
              globalStatus={{ id: '123', message: errorMessage }}
              top="large"
            >
              {errorMessage}
            </FormStatus>
          )}
          <Dialog
            confirmText="Delete user"
            confirmType="warning"
            description="This action cannot be undone."
            onConfirm={({ close }) => {
              onDelete.execute();
              close();
            }}
            title="Are you sure you want to delete this user?"
            top="small"
            triggerAttributes={{ text: 'Delete user', variant: 'signal' }}
            variant="confirmation"
          />
        </Section>
      </Container>
    </>
  );
}

function Psd2CertificateCard({
  certificate: {
    commonName,
    notBefore,
    notAfter,
    serialNumber,
    organizationName,
    organizationNumber,
    roles,
    deleted,
  },
}: {
  certificate: Psd2CertificateDto;
}): JSX.Element {
  return (
    <styles.Psd2CertificateCard>
      <Dl>
        <Dt>Company</Dt>
        <Dd>{organizationName}</Dd>
        {organizationNumber && (
          <>
            <Dt>Organization number</Dt>
            <Dd>{organizationNumber}</Dd>
          </>
        )}
        <Dt>Common name</Dt>
        <Dd>{commonName}</Dd>
        <Dt>Roles</Dt>
        <Dd>{roles.join(', ')}</Dd>
        <Dt>Serial number</Dt>
        <Dd>{serialNumber}</Dd>
        <Dt>Valid from</Dt>
        <Dd>{humanDate(notBefore)}</Dd>
        <Dt>Valid to</Dt>
        <Dd>{humanDate(notAfter)}</Dd>
      </Dl>
      {deleted && (
        <div className="deletedBox">
          <InfoCard centered icon={TrashIcon} text="Deleted by user" />
        </div>
      )}
    </styles.Psd2CertificateCard>
  );
}

import type { OrganizationDto } from '@portals/shared/admin/OrganizationDto';
import type { TeamDto } from '@portals/shared/admin/TeamDto';
import { useMemo } from 'react';
import useSWR from 'swr';

export default function useTeamsAndOrganizations() {
  const { data: teams, isValidating: teamsLoading } =
    useSWR<TeamDto[]>('/teams');

  const { data: organizations, isValidating: organizationsLoading } =
    useSWR<OrganizationDto[]>('/organizations');

  return useMemo(() => {
    if (!teams || !organizations) {
      return { teams: [], organizations: [], isLoading: false };
    }

    return {
      teams,
      organizations,
      isLoading: teamsLoading || organizationsLoading,
    };
  }, [organizations, organizationsLoading, teams, teamsLoading]);
}

import type { FeatureFlagInputDto } from '@portals/shared/admin/FeatureFlagDto';

import { request } from '@/request';

export function saveFeatureFlag(data: FeatureFlagInputDto): Promise<void> {
  return request('/feature-flags', 'post', data);
}

export function deleteFeatureFlag(id: string): Promise<void> {
  return request(`/feature-flags/${id}`, 'delete');
}

import {
  Accordion,
  Autocomplete,
  Checkbox,
  FormRow,
  FormStatus,
  H3,
  InfoCard,
  P,
  Space,
  Tag,
} from '@dnb/eufemia';
import { information } from '@dnb/eufemia/icons';
import type { ContentMetadataDto } from '@portals/shared/admin/ContentDto';
import {
  type Controller,
  useFieldArray,
} from '@portals/shared-frontend/hooks/useForm';
import { keyByAndMap } from '@portals/shared-frontend/utils';
import { useMemo } from 'react';

import useTeamsAndOrganizations from '../../hooks/useTeamsAndOrganizations';
import type { basicContentFormSchema } from '../../schemas';

import style from './index.module.css';

type Props = {
  controller: Controller<typeof basicContentFormSchema>;
  selectedParentContent: ContentMetadataDto | null;
};

export default function Visibility({
  controller,
  selectedParentContent,
}: Props) {
  const { teams, organizations } = useTeamsAndOrganizations();

  const teamsController = useFieldArray(controller, 'teams');
  const organizationsController = useFieldArray(controller, 'organizations');

  const teamNamesById = useMemo(() => {
    if (!selectedParentContent || selectedParentContent.isPublic) {
      return keyByAndMap(teams, 'id', 'name');
    }

    const filteredTeams = teams.filter(
      ({ id, organizationId }) =>
        selectedParentContent.teams.includes(id) ||
        selectedParentContent.organizations.includes(organizationId),
    );

    return keyByAndMap(filteredTeams, 'id', 'name');
  }, [selectedParentContent, teams]);

  const organizationsNamesById = useMemo(() => {
    if (!selectedParentContent || selectedParentContent.isPublic) {
      return keyByAndMap(organizations, 'id', 'name');
    }

    const filteredOrganizations = organizations.filter(({ id }) =>
      selectedParentContent.organizations.includes(id),
    );

    return keyByAndMap(filteredOrganizations, 'id', 'name');
  }, [organizations, selectedParentContent]);

  return (
    <Space className={style['AccordianContainer']} top="medium">
      <Accordion expanded icon_position="left" variant="plain">
        <Accordion.Header>
          <H3>Visibility</H3>
        </Accordion.Header>

        <Accordion.Content className={style['AccordianContent']}>
          <InfoCard
            bottom="large"
            icon={information}
            text={`
            Access is granted if a user belongs to any selected team or organization. Checking the public access box overrides all other settings.
          `}
            top="medium"
          />
          <FormRow top="small">
            <Checkbox
              checked={controller.values.isPublic}
              disabled={
                selectedParentContent !== null &&
                !selectedParentContent?.isPublic &&
                !controller.values.isPublic
              }
              label="Content should be available to everyone"
              onChange={({ checked }) =>
                controller.setValue('isPublic', checked)
              }
            />
          </FormRow>
          {selectedParentContent !== null &&
            !selectedParentContent?.isPublic && (
              <FormStatus
                state="warn"
                text="The parent of this page is NOT public, therefore this page cannot be public."
                top="small"
              />
            )}
          <FormRow direction="vertical" top="medium">
            <Autocomplete
              data={organizationsNamesById}
              disabled={
                Object.keys(organizationsNamesById).length === 0 ||
                controller.values.isPublic
              }
              label="Organizations"
              on_change={({ data }) =>
                organizationsController.push(data.selected_key)
              }
              prevent_selection
            />
            {organizationsController.items.length > 0 && (
              <Tag.Group label="Selected teams" top="x-small">
                {organizationsController.items.map(
                  ({ value, index, remove }) =>
                    organizationsNamesById[value] && (
                      <Tag
                        key={`tag-organization-${index}`}
                        onClick={remove}
                        variant="removable"
                      >
                        {organizationsNamesById[value]}
                      </Tag>
                    ),
                )}
              </Tag.Group>
            )}
            <P top="x-small">
              By adding a organization all users of the organization will be
              granted access to the content, regardless of team membership.
            </P>
          </FormRow>
          <FormRow bottom="medium" direction="vertical" top="medium">
            <Autocomplete
              data={teamNamesById}
              disabled={
                Object.keys(teamNamesById).length === 0 ||
                controller.values.isPublic
              }
              label="Teams"
              on_change={({ data }) => teamsController.push(data.selected_key)}
              prevent_selection
            />
            {teamsController.items.length > 0 && (
              <Tag.Group label="Selected teams" top="x-small">
                {teamsController.items.map(
                  ({ value, index, remove }) =>
                    teamNamesById[value] && (
                      <Tag
                        key={`tag-team-${index}`}
                        onClick={remove}
                        variant="removable"
                      >
                        {teamNamesById[value]}
                      </Tag>
                    ),
                )}
              </Tag.Group>
            )}
            <P top="x-small">
              By adding a team all members within that team will be granted
              access to the content.
            </P>
          </FormRow>
        </Accordion.Content>
      </Accordion>
    </Space>
  );
}

/* eslint-disable max-lines */
import {
  Button,
  Dd,
  Dl,
  Dt,
  FormRow,
  FormSet,
  H1,
  H2,
  H3,
  P,
  Radio,
  Section,
} from '@dnb/eufemia';
import type { AppDetailsDto } from '@portals/shared/admin/AppDto';
import type { TeamDetailsDto } from '@portals/shared/admin/TeamDto';
import type { UserDetailsDto } from '@portals/shared/admin/UserDto';
import { useAsync, useEufemiaForm } from '@portals/shared-frontend/hooks';
import { useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { z } from 'zod';

import {
  deleteApiAccess,
  deleteApiKey,
  deleteScopeAccess,
  updateApp,
} from '@/api/apps';
import BackButton from '@/components/BackButton';
import Card from '@/components/Card';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import { UserSection } from '@/components/UserSection';

import { TeamSection } from '../../components/TeamSection';
import { ApiKeys } from './components/ApiKeySection';
import { Clients } from './components/ClientSection';
import { ConsumedApis } from './components/ConsumedApiSection';

import style from './AppShow.module.css';

function convertBooleanOrNull(value?: boolean | null) {
  switch (value) {
    case true: {
      return 'yes';
    }
    case false: {
      return 'no';
    }
    default: {
      return 'null';
    }
  }
}

function convertStringToBooleanOrNull(value: string | null) {
  if (value?.toLowerCase() === 'yes') {
    return true;
  } else if (value?.toLowerCase() === 'no') {
    return false;
  } else {
    return null;
  }
}

export const appUpdateForm = z.object({
  internal: z.string().nullable(),
  accessType: z
    .string()
    .and(z.enum(['API_KEYS', 'O_AUTH_SYSTEM_TO_SYSTEM', 'null'])),
});

export default function AppsShow(): JSX.Element {
  const { appId } = useParams();
  const {
    data,
    isValidating: appLoading,
    mutate: mutateApp,
  } = useSWR<AppDetailsDto>(`/apps/${appId}`);

  const isTeam = data?.app?.owner?.type === 'team';
  const userId = isTeam ? null : data?.app?.owner?.id;
  const teamId = isTeam ? data?.app?.owner?.id : null;

  const { data: userDetails, isValidating: userLoading } =
    useSWR<UserDetailsDto>(userId ? `/users/${userId}` : null);

  const { data: team, isValidating: teamLoading } = useSWR<TeamDetailsDto>(
    teamId ? `/teams/${teamId}` : null,
  );

  const liveModeKeys = data?.apiKeys.filter(({ liveMode }) => liveMode);
  const testModeKeys = data?.apiKeys.filter(({ liveMode }) => !liveMode);
  const hasApiKeys =
    (liveModeKeys && liveModeKeys.length > 0) ||
    (testModeKeys && testModeKeys?.length > 0);

  const liveClients = data?.clients.filter(({ liveMode }) => liveMode);
  const testClients = data?.clients.filter(({ liveMode }) => !liveMode);
  const hasClients =
    (liveClients && liveClients.length > 0) ||
    (testClients && testClients?.length > 0);

  const onRevokeApiAccess = useAsync(
    async (apiId: string, liveMode: boolean) => {
      if (appId) {
        await deleteApiAccess({ appId, apiId, liveMode });
        mutateApp();
      }
    },
    [appId, mutateApp],
  );

  const onRevokeScopeAccess = useAsync(
    async (scopeId: string, liveMode: boolean) => {
      if (appId) {
        await deleteScopeAccess({ appId, scopeId, liveMode });
        mutateApp();
      }
    },
    [appId, mutateApp],
  );

  const onDeleteApiKey = useAsync(
    async (apiKeyId: string) => {
      if (appId) {
        await deleteApiKey(appId, apiKeyId);
        mutateApp();
      }
    },
    [appId, mutateApp],
  );

  const onDeleteClient = useAsync(async () => {
    alert('Not available until CIAM has implemented the functionality');
    // Params clientId: string
    // if (appId) {
    //   await deleteClient(clientId);
    //   mutate();
    // }
  }, []);

  const {
    controller: { setValues },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(appUpdateForm, {
    internal: convertBooleanOrNull(data?.app.internal),
    accessType: data?.app.accessType || 'null',
  });

  const isLoading = appLoading || userLoading || teamLoading;

  const waiting =
    onDeleteClient.waiting ||
    onRevokeApiAccess.waiting ||
    onRevokeScopeAccess.waiting ||
    onDeleteApiKey.waiting ||
    submitting;

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        if (appId) {
          await updateApp(appId, {
            accessType: data.accessType === 'null' ? null : data.accessType,
            internal: convertStringToBooleanOrNull(data.internal),
          });
        }
      },
      [appId],
    ),
  );

  useEffect(() => {
    setValues({
      internal: convertBooleanOrNull(data?.app.internal),
      accessType: data?.app.accessType || 'null',
    });
  }, [data?.app, setValues]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/apps">Home</BackButton>
      {waiting && <LoadingModal />}
      <Container centered size="small">
        <H1>App Overview</H1>
        <Section spacing="x-large" top="x-large">
          <H2>App</H2>
          <Dl top="large">
            <Dt>Id</Dt>
            <Dd>{data?.app?.id}</Dd>
            <Dt>Name</Dt>
            <Dd>{data?.app?.name}</Dd>
            <Dt>Description</Dt>
            <Dd>{data?.app?.description}</Dd>
            <FormSet label_direction="vertical" on_submit={onSubmit}>
              <Dt>Is internal app?</Dt>
              <Dd>
                <FormRow bottom="medium">
                  <Radio.Group
                    id="internal"
                    layout_direction="row"
                    {...register.radioGroup('internal')}
                  >
                    <Radio label="Yes" value="yes" />
                    <Radio label="No" value="no" />
                    <Radio label="Null" value="null" />
                  </Radio.Group>
                </FormRow>
              </Dd>
              <Dt>Access Type</Dt>
              <Dd>
                <FormRow bottom="medium">
                  <Radio.Group
                    id="accessType"
                    layout_direction="row"
                    {...register.radioGroup('accessType')}
                  >
                    <Radio label="API_KEYS" value="API_KEYS" />
                    <Radio
                      label="O_AUTH_SYSTEM_TO_SYSTEM"
                      value="O_AUTH_SYSTEM_TO_SYSTEM"
                    />
                    <Radio label="Null" value="null" />
                  </Radio.Group>
                </FormRow>
              </Dd>
              <Button
                disabled={submitting}
                icon="save"
                icon_position="left"
                type="submit"
              >
                Save
              </Button>
            </FormSet>
          </Dl>
        </Section>

        <Section spacing="x-large" style_type="white">
          <H2>Owner</H2>
          {team && (
            <TeamSection
              name={team.name}
              organizationName={team.organization?.name ?? 'N/A'}
              teamId={team.id}
            />
          )}
          {userDetails && (
            <UserSection
              email={userDetails.user.email}
              firstName={userDetails.user.firstName}
              lastName={userDetails.user.lastName}
              organizationName={userDetails.organization?.name ?? 'N/A'}
              userId={userDetails.user.id}
            />
          )}
        </Section>

        <Section spacing="x-large">
          <H2 bottom="small">Consumed APIs in Live Mode</H2>
          {data?.consumedLiveModeApisWithScopes && (
            <ConsumedApis
              apis={data.consumedLiveModeApisWithScopes}
              liveMode
              onRevokeApiAccess={onRevokeApiAccess.execute}
              onRevokeScopeAccess={onRevokeScopeAccess.execute}
            />
          )}
        </Section>
        <Section spacing="x-large" style_type="white">
          <H2 bottom="small">Consumed APIs in Test Mode</H2>
          {data?.consumedTestModeApisWithScopes && (
            <ConsumedApis
              apis={data.consumedTestModeApisWithScopes}
              liveMode={false}
              onRevokeApiAccess={onRevokeApiAccess.execute}
              onRevokeScopeAccess={onRevokeScopeAccess.execute}
            />
          )}
        </Section>

        <Section spacing="x-large">
          <H2>Exposed APIs</H2>
          {data?.exposedApisWithScopes?.length === 0 && (
            <P top="x-small">App does not expose any APIs</P>
          )}
          {data?.exposedApisWithScopes?.map((api) => {
            return (
              <Card className={style['APICard']} key={api.id}>
                <div className={style['APICard-Container']}>
                  <div>
                    <H3>{api.name}</H3>
                    {api?.scopes?.map((scopes) => (
                      <P key={scopes.id} top="medium">
                        {scopes.name}
                      </P>
                    ))}
                  </div>

                  <div className={style['APICard-Button']}>
                    <Button
                      element={Link}
                      icon="chevron-right"
                      to={`/apis/${api.id}`}
                      variant="secondary"
                    >
                      Show API
                    </Button>
                  </div>
                </div>
              </Card>
            );
          })}
        </Section>

        {hasApiKeys && (
          <Section spacing="x-large" style_type="white">
            <H2>API Keys</H2>
            {liveModeKeys && liveModeKeys.length > 0 && (
              <>
                <H3 bottom="x-small">Live keys</H3>
                <ApiKeys
                  apiKeys={liveModeKeys}
                  onDelete={onDeleteApiKey.execute}
                />
              </>
            )}
            {testModeKeys && testModeKeys.length > 0 && (
              <>
                <H3 bottom="x-small">Test keys</H3>
                <ApiKeys
                  apiKeys={testModeKeys}
                  onDelete={onDeleteApiKey.execute}
                />
              </>
            )}
          </Section>
        )}

        {hasClients && (
          <Section spacing="x-large" style_type="white">
            <H2>Clients</H2>
            {liveClients && liveClients.length > 0 && (
              <>
                <H3>Live clients</H3>
                <Clients
                  clients={liveClients}
                  onDelete={onDeleteClient.execute}
                />
              </>
            )}
            {testClients && testClients.length > 0 && (
              <>
                <H3>Test clients</H3>
                <Clients
                  clients={testClients}
                  onDelete={onDeleteClient.execute}
                />
              </>
            )}
          </Section>
        )}
      </Container>
    </>
  );
}

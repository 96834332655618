import { ProgressIndicator } from '@dnb/eufemia';

import Container from '../Container';

export default function LoadingPage(): JSX.Element {
  return (
    <Container centered size="small">
      <ProgressIndicator
        label_direction="vertical"
        show_label
        size="medium"
        top="x-large"
        type="linear"
      />
    </Container>
  );
}

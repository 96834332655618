import {
  Button,
  Dialog,
  Div,
  Input,
  Pagination,
  Skeleton,
  Space,
  Table,
  Td,
  Th,
  Tr,
} from '@dnb/eufemia';
import {
  edit as EditIcon,
  trash as TrashIcon,
  trash_medium as TrashMediumIcon,
  view as ViewIcon,
} from '@dnb/eufemia/icons';
import classnames from 'classnames';
import classNames from 'classnames';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import style from './index.module.css';

export interface Column<T> {
  header: string;
  attribute: Extract<keyof T, string>;
  render?: (value: T) => ReactNode;
}

export interface DataTableProps<T> {
  data: T[];
  onShow?(row: T): void;
  onDelete?(row: T): void;
  onEdit?(row: T): void;
  columns: Column<T>[];
  defaultSortKey: keyof T;
  reverseSort?: boolean;
  skeleton?: boolean;
  filterBy?: Array<keyof T>;
  rowsPerPage?: number;
  stateKey?: string;
  barContent?: JSX.Element;
}

function toString(value?: unknown): string {
  switch (typeof value) {
    case 'string': {
      return value;
    }
    case 'object': {
      return Array.isArray(value)
        ? value.map(toString).join(', ')
        : value?.toString() ?? '';
    }
    case 'number': {
      return value?.toString() ?? '';
    }
    default: {
      return '';
    }
  }
}

export default function DataTable<T>({
  data,
  columns,
  defaultSortKey,
  reverseSort = false,
  onShow,
  onDelete,
  onEdit,
  skeleton = false,
  filterBy,
  rowsPerPage = 15,
  stateKey,
  barContent,
}: DataTableProps<T>): JSX.Element {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<string>('');
  const [sortKey, setSortKey] = useState<keyof T>(defaultSortKey);
  const [isSortReversed, setIsSortReversed] = useState<boolean>(reverseSort);

  useEffect(() => setPage(1), [setPage, query]);

  // Reset state when stateKey changes
  useEffect(() => {
    setPage(1);
    setIsSortReversed(reverseSort);
    setSortKey(defaultSortKey);
  }, [stateKey, defaultSortKey, reverseSort]);

  const processedData = useMemo(() => {
    const copy = data.filter((row) => {
      if (!filterBy || !query) {
        return true;
      }

      const lowerCasedQuery = query.toLowerCase();

      for (const prop of filterBy) {
        if (toString(row[prop]).toLowerCase().includes(lowerCasedQuery)) {
          return true;
        }
      }

      return false;
    });

    if (sortKey) {
      copy.sort((a, b) => {
        if (typeof a[sortKey] === 'number' || a[sortKey] instanceof Date) {
          return a[sortKey] < b[sortKey] ? -1 : 1;
        } else {
          return toString(a[sortKey]).localeCompare(toString(b[sortKey]));
        }
      });

      if (isSortReversed) {
        copy.reverse();
      }
    }

    return copy;
  }, [data, sortKey, isSortReversed, filterBy, query]);

  const sortBy = useCallback(
    (attribute: keyof T) => {
      if (sortKey === attribute) {
        setIsSortReversed(!isSortReversed);
      } else {
        setSortKey(attribute);
        setIsSortReversed(false);
      }
    },
    [sortKey, setSortKey, isSortReversed, setIsSortReversed],
  );

  const numberOfPages = Math.ceil(processedData.length / rowsPerPage);
  const currentPageData = useMemo(
    () => processedData.slice((page - 1) * rowsPerPage, page * rowsPerPage),
    [processedData, rowsPerPage, page],
  );

  const hasActions = !!(onShow || onEdit || onDelete);
  const numberOfColumns = hasActions ? columns.length + 1 : columns.length;

  const rows = currentPageData.map((row, idx) => (
    <tr key={idx}>
      {columns.map((col) => (
        <td key={col.attribute}>
          {col.render ? col.render(row) : row[col.attribute]}
        </td>
      ))}
      {hasActions && (
        <td className={classnames('dnb-table--right', style['ActionsColumn'])}>
          {onShow && (
            <Button
              icon={ViewIcon}
              on_click={() => onShow(row)}
              right="x-small"
              size="medium"
              title="Show"
            />
          )}
          {onEdit && (
            <Button
              icon={EditIcon}
              on_click={() => onEdit(row)}
              right="x-small"
              size="medium"
              title="Edit"
            />
          )}
          {onDelete && (
            <Dialog
              confirmText="Delete"
              confirmType="warning"
              declineText="Cancel"
              description="This action cannot be undone."
              icon={TrashMediumIcon}
              onConfirm={({ close }) => {
                close();
                onDelete(row);
              }}
              title="Are you sure you want to delete this?"
              triggerAttributes={{ icon: TrashIcon }}
              variant="confirmation"
            />
          )}
        </td>
      )}
    </tr>
  ));

  return (
    <Skeleton show={skeleton}>
      <Space bottom="small" className={style['Bar']}>
        {barContent}
        {filterBy && (
          <Input
            clear
            icon="loupe"
            on_change={({ value }) => setQuery(value)}
            placeholder="Search"
            value={query}
          />
        )}
      </Space>
      <Pagination
        className={numberOfPages < 2 ? style['HidePagination'] : ''}
        current_page={page}
        on_change={({ pageNumber }) => setPage(pageNumber)}
        page_count={numberOfPages}
      >
        <Div className={style['TableWrapper']}>
          <Table>
            <thead>
              <tr>
                {columns.map((col) => (
                  <th
                    className={classNames({
                      'dnb-table--sortable': true,
                      'dnb-table--active': sortKey === col.attribute,
                      'dnb-table--reversed':
                        sortKey === col.attribute && isSortReversed,
                    })}
                    key={col.attribute}
                    scope="col"
                  >
                    <Button
                      icon="arrow-down"
                      on_click={() => sortBy(col.attribute)}
                      text={col.header}
                      title="Sort table row"
                      variant="tertiary"
                      wrap
                    />
                  </th>
                ))}
                {hasActions && (
                  <Th className="dnb-table--right" scope="col">
                    Actions
                  </Th>
                )}
              </tr>
            </thead>
            <tbody>
              {rows.length > 0 ? (
                rows
              ) : (
                <Tr className="dnb-table--small">
                  <Td colSpan={numberOfColumns}>
                    <i>No matching data</i>
                  </Td>
                </Tr>
              )}
            </tbody>
          </Table>
        </Div>
      </Pagination>
    </Skeleton>
  );
}

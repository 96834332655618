import type {
  ApiIncidentDto,
  ApiIncidentInputDto,
} from '@portals/shared/admin/ApiIncidentDto';
import { format } from 'date-fns';

import type { IncidentFormData } from './schemas';

export function dateAndTimeToISOString(date: string, time: string) {
  return new Date(`${date} ${time}`).toISOString();
}

export function dtoToForm(values: ApiIncidentDto): IncidentFormData {
  const reportedAt = new Date(values.reportedAt);
  const resolvedAt = values.resolvedAt ? new Date(values.resolvedAt) : null;

  return {
    id: values.id,
    type: values.type,
    severity: values.severity.toString(),
    apiIds: [values.apiId],
    description: values.description ?? '',
    reportedAtDate: format(reportedAt, 'yyyy-MM-dd'),
    reportedAtTime: format(reportedAt, 'HH:mm'),
    resolvedAtDate: resolvedAt ? format(resolvedAt, 'yyyy-MM-dd') : '',
    resolvedAtTime: resolvedAt ? format(resolvedAt, 'HH:mm') : '',
    updates: values.updates.map((update) => {
      const reportedAt = new Date(update.reportedAt);
      return {
        id: update.id,
        description: update.description || '',
        reportedAtDate: format(reportedAt, 'yyyy-MM-dd'),
        reportedAtTime: format(reportedAt, 'HH:mm'),
      };
    }),
  };
}

export function formToDto({
  id,
  type,
  severity,
  apiIds,
  description,
  reportedAtDate,
  reportedAtTime,
  resolvedAtDate,
  resolvedAtTime,
  updates,
}: IncidentFormData): ApiIncidentInputDto {
  return {
    id,
    type,
    severity: Number.parseInt(severity),
    apiIds,
    description: description || null,
    reportedAt: dateAndTimeToISOString(reportedAtDate, reportedAtTime),
    resolvedAt:
      resolvedAtDate && resolvedAtTime
        ? dateAndTimeToISOString(resolvedAtDate, resolvedAtTime)
        : null,
    updates: updates.map(
      ({ id, reportedAtDate, reportedAtTime, description }) => ({
        id,
        description,
        reportedAt: dateAndTimeToISOString(reportedAtDate, reportedAtTime),
      }),
    ),
  };
}

import { H1 } from '@dnb/eufemia';
import type { ApiWithOwnerDto } from '@portals/shared/admin/ApiDto';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import LoadingPage from '@/components/LoadingPage';

import ApiListTable from './ApiListTable';

export default function ApiList(): JSX.Element {
  const { data: apis, isValidating: apisLoading } =
    useSWR<ApiWithOwnerDto[]>('/api');

  if (apisLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>
      <H1 top="large">APIs</H1>
      <ApiListTable apis={apis || []} showFilters />
    </>
  );
}

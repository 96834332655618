import {
  Button,
  Dialog,
  Drawer,
  FormStatus,
  H3,
  Icon,
  Section,
} from '@dnb/eufemia';
import {
  reset_medium as ResetIcon,
  trash_medium as TrashIcon,
} from '@dnb/eufemia/icons';
import type { ContentRecoveryDto } from '@portals/shared/admin/ContentRecoveryDto';
import type { UserDto } from '@portals/shared/admin/UserDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { hasOwnProperty, keyBy } from '@portals/shared-frontend/utils';
import { useMemo, useState } from 'react';
import useSWR, { mutate } from 'swr';

import { deleteRecoveredContent, recoverContent } from '@/api/content';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import { humanDatetime } from '@/utils';

interface ContentRow {
  id: string;
  path: string;
  deletedBy: string;
  createdAt: string;
  updatedAt: string;
}

const COLUMNS: Column<ContentRow>[] = [
  { header: 'Path', attribute: 'path' },
  { header: 'Deleted by', attribute: 'deletedBy' },
  {
    header: 'Deleted',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDatetime(createdAt),
  },
];

export default function RecoverContentTable() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRecoverDialog, setOpenRecoverDialog] = useState(false);
  const [currentId, setCurrentId] = useState<string>();
  const [error, setError] = useState<string>();

  const {
    data,
    isValidating,
    mutate: mutateRecoverContent,
  } = useSWR<ContentRecoveryDto[]>('/content-recovery');

  const { data: users } = useSWR<UserDto[]>('/users');

  const usersById = useMemo(() => {
    if (!users) {
      return {};
    }
    return keyBy(users, 'id');
  }, [users]);

  const rows = useMemo(() => {
    if (!data || !users) {
      return [];
    }

    return data.map((item) => ({
      id: item.id,
      path: item.path,
      deletedBy: usersById[item.deletedBy].email,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }));
  }, [data, users, usersById]);

  const contentById = useMemo(() => {
    if (!data) {
      return {};
    }
    return keyBy(data, 'id');
  }, [data]);

  const onRecover = useAsync(async () => {
    try {
      if (currentId) {
        await recoverContent(currentId);
        mutateRecoverContent();
        mutate('/content');
        setOpenDeleteDialog(false);
        setOpenRecoverDialog(false);
      }
    } catch (error) {
      if (
        hasOwnProperty(error, 'message') &&
        typeof error.message === 'string'
      ) {
        setError(error.message);
      }
    }
  }, [currentId, mutateRecoverContent]);

  const onDelete = useAsync(async () => {
    try {
      if (currentId) {
        await deleteRecoveredContent(currentId);
        mutateRecoverContent();
        setOpenDeleteDialog(false);
        setOpenRecoverDialog(false);
      }
    } catch (error) {
      if (
        hasOwnProperty(error, 'message') &&
        typeof error.message === 'string'
      ) {
        setError(error.message);
      }
    }
  }, [currentId, mutateRecoverContent]);

  if (isValidating) {
    return <LoadingPage />;
  }

  return (
    <>
      {(onRecover.waiting || onDelete.waiting) && <LoadingModal />}
      <DataTable
        columns={COLUMNS}
        data={rows}
        defaultSortKey="path"
        onShow={({ id }) => {
          setOpenDrawer(true);
          setCurrentId(id);
        }}
      />

      {openDrawer && (
        <Drawer
          onClose={() => setOpenDrawer(false)}
          openState
          title={contentById[currentId!].path}
        >
          <Section spacing="large" style_type="white">
            <H3>Deleted content</H3>
            <pre style={{ overflow: 'scroll' }}>
              {currentId &&
                JSON.stringify(contentById[currentId].recoveryBlob, null, 2)}
            </pre>
          </Section>
          <Section spacing="large" style_type="white">
            <Button on_click={() => setOpenRecoverDialog(true)}>
              Recover content
            </Button>
            {openRecoverDialog && (
              <Dialog
                confirmText="Recover content"
                confirmType="warning"
                declineText="Cancel"
                description={
                  <>
                    This will recover all this content, and might change the
                    content rendered in the Developer Portal. Are you sure you
                    want to do this?
                  </>
                }
                icon={<Icon icon={ResetIcon} />}
                omitTriggerButton
                onConfirm={onRecover.execute}
                onDecline={() => setOpenRecoverDialog(false)}
                openState
                title="Recover deleted content"
                variant="confirmation"
              />
            )}

            <Button
              left="small"
              on_click={() => setOpenDeleteDialog(true)}
              variant="signal"
            >
              Delete
            </Button>
            {openDeleteDialog && (
              <Dialog
                confirmText="Delete"
                confirmType="warning"
                declineText="Cancel"
                description={
                  <>
                    This will delete the recovered content, and it can never be
                    recovered again. Are you sure you want to delete this?
                  </>
                }
                icon={TrashIcon}
                omitTriggerButton
                onConfirm={onDelete.execute}
                onDecline={() => setOpenDeleteDialog(false)}
                openState
                title="Delete recovered content"
                variant="confirmation"
              />
            )}
            {error && <FormStatus text={error} />}
          </Section>
        </Drawer>
      )}
    </>
  );
}

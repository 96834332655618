import style from './index.module.css';

type colors =
  | 'ocean-green'
  | 'emerald-green'
  | 'sea-green'
  | 'mint-green'
  | 'summer-green'
  | 'accent-yellow'
  | 'indigo'
  | 'violet'
  | 'sky-blue'
  | 'lavender'
  | 'sand-yellow'
  | 'pistachio'
  | 'sea-green-30'
  | 'mint-green-50'
  | 'mint-green-25'
  | 'mint-green-12'
  | 'accent-yellow-30'
  | 'signal-orange'
  | 'fire-red'
  | 'fire-red-8'
  | 'success-green'
  | 'black'
  | 'black-80'
  | 'black-55'
  | 'black-20'
  | 'black-8'
  | 'black-3';

interface DotProps {
  color: colors;
}

export default function Dot({ color }: DotProps): JSX.Element {
  return (
    <span
      className={style['Dot']}
      style={{ backgroundColor: `var(--color-${color})` }}
    />
  );
}
